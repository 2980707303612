import React from "react";
import {Accordion, Card} from "react-bootstrap";
// white diam
import {Shape} from "./Basic/Shape"
import {Price} from "./Basic/Price";
import {Carat} from "./Basic/Carat";
import {Color} from "./Basic/Color"
import {Clarity} from "./Basic/Clarity";
import {Finish} from "./Basic/Finish";
// white fancy diam
import {ShapeFancy} from "./BasicFancy/Shape"
import {PriceFancy} from "./BasicFancy/Price";
import {CaratFancy} from "./BasicFancy/Carat";
import {FancyColor} from "./BasicFancy/Color"
import {ClarityFancy} from "./BasicFancy/Clarity";
import {FinishFancy} from "./BasicFancy/Finish";
// labgrown
import {ShapeLab} from "./BasicLab/Shape"
import {PriceLab} from "./BasicLab/Price";
import {CaratLab} from "./BasicLab/Carat";
import {ColorLab} from "./BasicLab/Color"
import {ClarityLab} from "./BasicLab/Clarity";
import {FinishLab} from "./BasicLab/Finish";
// labgrown fancy
import {ShapeLabFancy} from "./BasicLabFancy/Shape"
import {PriceLabFancy} from "./BasicLabFancy/Price";
import {CaratLabFancy} from "./BasicLabFancy/Carat";
import {FancyLabColor} from "./BasicLabFancy/Color"
import {ClarityLabFancy} from "./BasicLabFancy/Clarity";
import {FinishLabFancy} from "./BasicLabFancy/Finish";
import { useSelector } from "react-redux";

// import axios from "axios";
// import { NODE_API_URL } from "../../../../../env_config";

export const Basic = ({formik,setSearchData,diamond_type,custompage,setLoader,sorting,fancy,ruleData,naturalData,naturalFancyData,labData,labFancyData,setFancy,setFancyLab,fancyLab,language}) => {
    // console.log("naturalDataBasic",naturalData)
    // console.log("labDataBasic",labData)
    // console.log("naturalDataBasic",naturalFancyData)
    // console.log("labDataBasic",labData)
    // console.log("---------------------------------------------------------------------------------------------------------------")
    const { hidePrice } = useSelector((state) => state.CcModeSettingReducer);
       return (
        <>
            {/* <Accordion className="search_accordion" defaultActiveKey="1"> */}
                <Card className="border-0">
                    {/* <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        Basic
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1"> */}
                        <Card.Body className="p-0">
                            {diamond_type === "N" ?
                                !fancy ? 
                                    naturalData[0] && naturalData[0].shape ? <Shape formik={formik} naturalData={naturalData} language={language}/> : null
                                    : naturalFancyData[0] && naturalFancyData[0].shape ? <ShapeFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language}/> : null
                            : 
                                !fancyLab ? 
                                    labData[0] && labData[0].lab_shape ? <ShapeLab formik={formik} labData={labData}  language={language}/> : null
                                    : labFancyData[0] && labFancyData[0].lab_shape ? <ShapeLabFancy formik={formik} labFancyData={labFancyData}  language={language}/>: null
                            }

                            {diamond_type === "N" ?
                                !fancy ? 
                                    <Carat formik={formik} naturalData={naturalData} language={language}/> 
                                    : <CaratFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language}/> 
                            :
                                !fancyLab ?
                                    <CaratLab formik={formik} labData={labData} language={language}/> 
                                    : <CaratLabFancy formik={formik} labFancyData={labFancyData} language={language}/> 
                            }
                           
                            {
                                !hidePrice && (
                                    <>
                                        {diamond_type === "N" ?
                                            !fancy ? 
                                                <Price formik={formik} naturalData={naturalData} language={language}/> 
                                                : <PriceFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language}/>
                                        : 
                                            !fancyLab ?
                                                <PriceLab formik={formik} labData={labData} language={language}/>
                                                : <PriceLabFancy formik={formik} labFancyData={labFancyData} language={language}/>
                                        } 
                                    </>         
                                )
                            }                             
                            {diamond_type === "N" ?
                                !fancy ?
                                    naturalData[0] && naturalData[0].color ? <Color formik={formik} naturalData={naturalData} setFancy={setFancy} fancy={fancy} naturalFancyData={naturalFancyData} language={language} /> : null
                                    : <FancyColor formik={formik} naturalData={naturalData} setFancy={setFancy} fancy={fancy} naturalFancyData={naturalFancyData}  language={language}/>
                                    
                            :
                                !fancyLab ?
                                    <ColorLab formik={formik} labData={labData} setFancyLab={setFancyLab} fancyLab={fancyLab} labFancyData={labFancyData} language={language}/>
                                    : <FancyLabColor formik={formik}  setFancyLab={setFancyLab} fancyLab={fancyLab} labFancyData={labFancyData} labData={labData} language={language}/>
                                    
                            }

                            { diamond_type === "N" ?
                                !fancy ? 
                                    naturalData[0] && naturalData[0].clarity ? <Clarity formik={formik} naturalData={naturalData} language={language}/> : null
                                    : naturalFancyData[0] && naturalFancyData[0].clarity ? <ClarityFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language}/> : null
                                : 
                                !fancyLab ? 
                                    labData[0] && labData[0].lab_clarity ? <ClarityLab formik={formik} labData={labData} language={language}/> : null
                                    : labFancyData[0] && labFancyData[0].lab_clarity   ? <ClarityLabFancy formik={formik} labFancyData={labFancyData} language={language}/> : null
                            }

                            { diamond_type === "N" ?
                                !fancy ? 
                                    (naturalData[0] && naturalData[0].cut) || (naturalData[0] && naturalData[0].polish) || (naturalData[0] && naturalData[0].symmetry) ? <Finish formik={formik} naturalData={naturalData} language={language}/> : null
                                    : (naturalFancyData[0] && naturalFancyData[0].cut) || (naturalFancyData[0] && naturalFancyData[0].polish) || (naturalFancyData[0] && naturalFancyData[0].symmetry) ? <FinishFancy formik={formik} naturalData={naturalData} naturalFancyData={naturalFancyData} language={language}/> : null
                                : 
                                !fancyLab ? 
                                    (labData[0] && labData[0].lab_cut) || (labData[0] && labData[0].lab_polish) || (labData[0] && labData[0].lab_symmetry) ? <FinishLab formik={formik} labData={labData} language={language} />:null
                                    : (labFancyData[0] && labFancyData[0].lab_cut) || (labFancyData[0] && labFancyData[0].lab_polish) || (labFancyData[0] && labFancyData[0].lab_symmetry) ? <FinishLabFancy formik={formik} labFancyData={labFancyData} language={language} /> : null
                            }
                        </Card.Body>
                    {/* </Accordion.Collapse> */}
                </Card>
            {/* </Accordion> */}
        </>
    );
};
