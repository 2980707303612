/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect,useState,useMemo,useContext} from "react";
import { Modal, Button, OverlayTrigger, Tooltip  } from "react-bootstrap";
import { Link,useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import VideoIframe from "../../../popup/VideoIframe";
import { StoreDiamondDB ,GetDiamondDB} from "../../../../_helpers/IndexedDB";
import axios from "axios";
import {NODE_API_URL} from "./../../../../../env_config"
import { toAbsoluteUrl } from "../../../../_helpers";
import { FaVideo } from "react-icons/fa";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
// import { green } from '@mui/material/colors'
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import { useDispatch, useSelector } from "react-redux";
import { updateShortlistStoneCount } from "../../../../../redux/commonRedux/common.actions";

export const GridCard = (props) => {
    const history = useHistory();
    const [diamondsearch,setDiamondSearch] = useState([])
    const [newprops,setProps] = useState(props)
    const [update,setUpdate] = useState(props.Shortlisted);
    const [showWithoutModel, setShowWithoutModel] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const id = useMemo(()=>localStorage.getItem("C_id"),[])
    const userCurrency = useMemo(()=>localStorage.getItem("userCurrency"),[])
    const usercur = JSON.parse(userCurrency)
    const dispatch = useDispatch();
    const { hidePrice, hideCertificateNumber } = useSelector(
      (state) => state.CcModeSettingReducer
    );

    const shortListedStoneCount = useSelector((state) => {
      return state.ShortlistStoneReducer?.shortlistStoneCount;
    });

    useEffect(() => {
        GetDiamondDB().then(response => {
            setDiamondSearch(response)
        })
    },[])
    function handelDetails(data){
        history.push({  pathname: '/details', state:{
            StockID:data.STOCK_ID,
            diamond_type:data.COLOR?data.diamond_type:data.diamond_type + "F"
        }})
        // const finalData = `{ "JsonData":  [{"ProductId":${props.ProductId}}] , "CustomerId": ${user.CustomerId}, "ConsumerId":${id=== "" ? "-1" : id}, "WLDiamondType": "${props.WLDiamondType}" }`;
        // axios({
        //     method: "POST",
        //     url: DETAILPAGE_LOG,
        //     headers: {
        //         "Access-Control-Allow-Origin": "*",
        //         "Content-Type": "application/json",
        //     },
        //     data: finalData,
        // })
        // .then(function(res) {
        //     // setSuccessOpen(true);
        //     // setShowWithoutModel(false);
        // })
        // .catch(function(res) {
        //     // console.log("error",values);
        //     console.log(res);
        // });
        window.scrollTo(0, 0)
    }
     // -------------------------------------------------

     const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
     // Get device information
     function detectDevice() {
        // Check if the user agent contains the substring "Mobi"
        return /Mobi/.test(navigator.userAgent) ? "Mobile" : "Pc";
    }

    function detectOS() {
        const userAgent = navigator.userAgent;
        if (/Windows/.test(userAgent)) {
            return "Windows";
        } else if (/Macintosh/.test(userAgent)) {
            return "Mac OS";
        } else if (/Linux/.test(userAgent)) {
            return "Linux";
        } else if (/Android/.test(userAgent)) {
            return "Android";
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
            return "iOS";
        } else {
            return "Unknown";
        }
    }

    // -------------------------------------------------
    function handleShortlist(props) {

        const deviceType = detectDevice();
        const os = detectOS();

        const deviceInfo = `${deviceType} - ${os}`; // Combine device type and OS into a single field
        // const newdiamondata = diamondsearch.map(value => {
        //     if(value.STOCK_ID === props.STOCK_ID){
        //         value.Shortlisted = 1
        //     }
        //     return value
        // })
        // setProps({
        //     ...props,
        //     Shortlisted:1
        // })
        // console.log(newdiamondata,"newdiamondata")
        // StoreDiamondDB(newdiamondata)
        const values = JSON.stringify({
            Tag: "I",
            Id: 0,
            CustomerId: user.CustomerId,
            ConsumerId: id,
            ProductId: props.STOCK_ID,
            CertiNo: props.CERTIFICATE_NO,
            WLDiamondType: props.diamond_type,
            Currency: props.CURRENCY,
            CurrencyConversionRate: props.CURRENCY_RATE,
            MarkUpNatural: 0,
            MarkUpNaturalFancy: 0,
            MarkUpLab: 0,
            MarkUpLabFancy: 0,
            OurRate: props.calculateprice?props.calculateprice.T_C_Rate:0,
            OurPrice: props.calculateprice?props.calculateprice.total_our_price:0,
            MarkUpRate: Math.round(props.markupdollpercar * 100)/100,
            MarkUpPrice: Math.round(props.markupprice * 100)/100,
            // PRICE_PER_CTS: props.PRICE_PER_CTS,
            // TOTAL_PRICE: props.TOTAL_PRICE,
            CreatedAt: "0000-00-00 00:00:00",
            Tax: props.taxvalue,
            ip:localStorage.getItem("ipData"),
            device:deviceInfo,
        });
        axios({
            method: "POST",
            url: `${NODE_API_URL}/ccmode/shortlistStone`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            data: values,
        })
            .then(function(res) {
                // console.log(res.data);
                setUpdate(1)
                // setSuccessOpen(true);
                // setShowWithoutModel(false);
                dispatch(
                  updateShortlistStoneCount({
                    shortListedStoneCount: shortListedStoneCount + 1,
                  })
                );

                let diamond_details = {
                    // "ProductId":props.STOCK_ID,
                    // "ListName":"Test",
                    // "ConsumerName":"pancham",
                    // "EMail":"Kumarpancham8873@gmail.com",
                    // "ContactNo":"7004896788",
                    // "Notes":"Test Mode",
                    "STOCK_ID": props.STOCK_ID,
                    "Shipping_Days": props.Shipping_Days,
                    "SHAPE": props.SHAPE,
                    "CARAT": props.CARAT,
                    "CLARITY": props.CLARITY,
                    "CUT": props.CUT,
                    "POLISH": props.POLISH,
                    "SYMMETRY": props.SYMMETRY,
                    "FLUORESCENCE": props.FLUORESCENCE,
                    "LAB": props.LAB,
                    "CERTIFICATE_LINK": props.CERTIFICATE_LINK,
                    "WIDTH": props.WIDTH,
                    "LENGTH": props.LENGTH,
                    "DEPTH": props.DEPTH,
                    "DEPTH_PER": props.DEPTH_PER,
                    "TABLE_PER": props.TABLE_PER,
                    "CROWNANGLE": props.CROWNANGLE,
                    "CROWNHEIGHT": props.CROWNHEIGHT,
                    "PAVILIONHEIGHT": props.PAVILIONHEIGHT,
                    "PAVILIONANGLE": props.PAVILIONANGLE,
                    "CERTIFICATE_NO": props.CERTIFICATE_NO,
                    "MARKUP_PRICE_PER_CTS": props.PRICE_PER_CTS,
                    "TOTAL_PRICE": props.TOTAL_PRICE,
                    "ORIGIN": props.ORIGIN,
                    "TREATMENT": props.TREATMENT,
                    "BRAND": props.BRAND,
                    "SHADE": props.SHADE,
                    "MILKY": props.MILKY,
                    "EYE_CLEAN": props.EYE_CLEAN,
                    "COUNTRY": props.COUNTRY,
                    "CURRENCY": props.CURRENCY,
                    "CURRENCY_RATE": props.CURRENCY_RATE,
                    "CULET": props.CULET,
                    "GIRDLE": props.GIRDLE,
                    "GIRDLE_CONDITION": props.GIRDLE_CONDITION,
                    "KEY_TO_SYMBOL": props.KEY_TO_SYMBOL,
                    "RATIO": props.RATIO,
                    "IMAGE": props.IMAGE,
                    "VIDEO": props.VIDEO,
                    "FANCY_INTENSITY": props.FANCY_INTENSITY,
                    "FANCY_OVERTONE": props.FANCY_OVERTONE,
                    "FANCY_COLOR": props.FANCY_COLOR,
                    "COLOR": props.COLOR,
                    "diamond_type": props.diamond_type,
                    "girdle_thin": props.girdle_thin,
                    "Pav_Ag": props.Pav_Ag,
                    "Crn_Ag": props.Crn_Ag,
                    "calculateprice": props.calculateprice.total_our_price,
                    "Shortlisted": props.Shortlisted,
                    "taxvalue": props.taxvalue
                }
                props.setShortlist(!props.shortlist)
                // axios({
                //     method: "POST",
                //     url: `${NODE_API_URL}/ccmode/storeUserACtivity`,
                //     headers: { 
                //         "Content-Type": "application/json"
                //     //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                //      },
                //     data: {
                //         user_id:user.CustomerId,
                //         activity_type:"Ccmode Shortlist",
                //         user_agent:navigator.userAgent,
                //         activity_data:{
                //             ...diamond_details
                //         },
                //         ip_address:localStorage.getItem("ipData"),
                //         consumer_id:id,
                //         diamond_type:props.diamond_type
                //     }
                // }).then(response => {
                // }).catch(error => {
                // })
        //         const newdiamondata = props.natData.map(value => {
        //     if(value.STOCK_ID === props.STOCK_ID){
        //         value.Shortlisted = 1,
        //     }
        //     return value
        // })
        //         props.setNatData(newdiamondata)
            })
            .catch(function(res) {
                console.log("error",values);
                // console.log(res);
            });
    }
    function Currency(){
        if (localStorage.getItem("user_currency") === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SGD") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "THB") {
            const  currencyIcon = "฿"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "MYR") {
            const  currencyIcon = "MYR"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "BND") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "DKK") {
            const  currencyIcon = "DKK"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SEK") {
            const  currencyIcon = "SEK"
            return currencyIcon
        }
    }
    function certificateLink(){
        if (props.LAB === "IGI"){
            const  certificate_link = `https://www.igi.org/viewpdf.php?r=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.CERTIFICATE_LINK){
            const certificate_link = props.CERTIFICATE_LINK
            return certificate_link
        }
    }
    function handleClose(event, reason) {
        setOpen(false);
        setShowVideo(false)
        setSuccessOpen(false);
        setShowWithoutModel(false);
    }
    function withoutSession() {
        setOpen(true);
    }
    function openModal(data) {
        if(!update){
            setShowWithoutModel(true);
            handleShortlist(data)
        }else{
            history.push({  pathname: '/shortlist'}) 
        }
    }
    function openVideoModal() {
        setShowVideo(true)
        // setOpen(true);
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <>
        <div
            className={`col-sm-6 col-md-4 col-lg-3 mb-5  ${
                props.diamond_type === "N" ? "natTab" : "labTab"
            }`}
            key={props.STOCK_ID}
            id={props.STOCK_ID}
        >
            <div className="row m-0 card gridData">
                <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200" style={{height:"220px"}}>
                    {props.IMAGE ? (
                        // <img
                        // className="img-fluid"
                        // alt={props.SHAPE}
                        // src={props.IMAGE}
                        //     // src={toAbsoluteUrl(
                        //     //     `/media/shape/${props.SHAPE.toLowerCase()}-no.png`
                        //     // )}
                            
                        // />
                        <LazyLoadImage
                            className="img-fluid cursor-pointer"
                            onClick={() => handelDetails(props)}
                            src={ props.IMAGE}
                            alt={props.SHAPE}
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/${props.SHAPE.toLowerCase()}-no.png`
                                    )
                                }}
                            effect="blur" 
                        />
                    ) : (
                        <img
                            className="img-fluid cursor-pointer"
                            onClick={() => handelDetails(props)}
                            alt="shape"
                            onError={e => {
                                e.currentTarget.src = toAbsoluteUrl(
                                    `/media/shape/noimage.jpg`
                                )
                            }}
                            src={toAbsoluteUrl(
                                `/media/shape/${props.SHAPE.toLowerCase()}-no.png`
                            )}
                        />
                        
                    )}
                </div>
                <div className="col-12 my-3 d-flex justify-content-between align-items-center">
                    {
                        hideCertificateNumber ? 
                        <span className="lab-color">{props.LAB}</span> :
                        <a
                            id={props.CERTIFICATE_NO}
                            href={certificateLink()}
                            target="_blank"
                            rel="noreferrer"
                            className="font-size-sm text-primary"
                        >
                            {props.LAB}
                        </a>
                    }                    
                    <span className="font-size-sm text-primary">
                        {props.VIDEO ? (
                        <>
                            <FaVideo className="font-size-sm text-primary cursor-pointer"  onClick={() => openVideoModal(props)} />
                            <VideoIframe
                                showVideo={showVideo}
                                handleClose={handleClose}
                                src={props.diamond_type === "N" ? `https://pro360video.com/video.php?refno=${props.CERTIFICATE_NO}` : `https://pro360video.com/labgrown.php?refno=${props.CERTIFICATE_NO}` }
                            />
                        </>
                        ) : (
                            <span id={props.VIDEO} > </span>
                        )}
                    </span>
                </div>
                <div className="col-12 text-truncate">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                {props.CARAT?props.CARAT.toFixed(2):""} {props.SHAPE}{" "}
                                {(props.FANCY_COLOR) ? (<>{props.FANCY_INTENSITY} {props.FANCY_OVERTONE} {props.FANCY_COLOR}</>): props.COLOR}{" "}
                                {props.CLARITY} {props.CUT}{" "}
                                {props.POLISH} {props.SYMMETRY} {props.FLUORESCENCE} <br />
                                M: {props.LENGTH?props.LENGTH.toFixed(2):0} x {props.WIDTH?props.WIDTH.toFixed(2):0} x {props.DEPTH?props.DEPTH.toFixed(2):0} T:{props.TABLE_PER?props.TABLE_PER.toFixed(2):0}%  D:{props.DEPTH_PER?props.DEPTH_PER.toFixed(2):0}%  R:{props.RATIO}
                            </Tooltip>
                        }
                    >
                        <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                            <span className="pr-2">
                                {props.CARAT?props.CARAT.toFixed(2):""}
                            </span>
                            <span className="pr-2">{props.SHAPE}</span>
                            { (props.FANCY_COLOR ) ?
                                (
                                    <>
                                         <span className="pr-2">{props.FANCY_INTENSITY}</span>
                                         <span className="pr-2">{props.FANCY_OVERTONE}</span>
                                         <span className="pr-2">{props.FANCY_COLOR}</span>
                                    </>
                                ) : (
                                    <>
                                         <span className="pr-2">{props.COLOR}</span>
                                    </>
                                )
                            }
                            <span className="pr-2">{props.CLARITY}</span>
                            <span className="pr-2">{props.CUT}</span>
                            <span className="pr-2">{props.POLISH}</span>
                            <span className="pr-2">{props.SYMMETRY}</span>
                            <span className="pr-2">{props.FLUORESCENCE}</span>
                        </span>
                        
                    </OverlayTrigger>
                    <hr className="w-75 m-1 px-5 pt-2 pb-0" />
                </div>

                {
                    !hidePrice && (
                        <div className="col-12 text-center">
                            <div className="font-size-h1 font-weight-bolder text-primary">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="currency_t">
                                        {Currency()}/CT {props.PRICE_PER_CTS?numberWithCommas(props.PRICE_PER_CTS):""}
                                    </Tooltip>
                                }
                            >
                            <span> {Currency()}{props.TOTAL_PRICE?numberWithCommas(props.TOTAL_PRICE):""}</span>
                            </ OverlayTrigger>
                            </div>
                            <hr className="w-75 m-1 px-5 py-2" />
                        </div>
                    )
                }                
                <div className="col-12 d-flex align-items-center justify-content-between mb-5">
                    <button className="btn btn-secondary font-weight-bold" onClick={() => handelDetails(props)}>
                        {props.language.CCMODE_THEME1_GRID_DETAILBTN}
                    </button>
                    {id === "-1" || id === "-2" || id === "" ? (
                        <>
                            <button
                                onClick={() => withoutSession()}
                                className={`btn font-weight-bold ${props.Shortlisted ? "btn-info" : "btn-danger"}`}
                            >
                                {props.Shortlisted ? props.language.CCMODE_THEME1_GRID_SHORTLISTEDBTN : props.language.CCMODE_THEME1_GRID_SHORTLISTBTN}
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                key={props.STOCK_ID}
                                onClick={() => openModal(props)}
                                className={`btn font-weight-bold ${props.Shortlisted ? "btn-info" : "btn-primary"}`}
                            >
                                {props.Shortlisted ? props.language.CCMODE_THEME1_GRID_SHORTLISTEDBTN : props.language.CCMODE_THEME1_GRID_SHORTLISTBTN}
                            </button>
                            <Modal
                                show={showWithoutModel}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Body>
                                    <div className="text-center mb-10"> <h3 className="text-dark font-weight-bold">You have just added</h3></div>
                                    <div className="d-flex w-100 mb-4 pb-4 px-4 justify-content-between">
                                        <div className="d-flex">
                                            <div className="text-center p-0">
                                                {props.IMAGE &&  props.IMAGE !=="null" ? (
                                                    <img
                                                        className="img-fluid mr-2"
                                                        alt={props.SHAPE}
                                                        src={props.IMAGE}
                                                        onError={e => {
                                                            e.currentTarget.src = toAbsoluteUrl(
                                                                `/media/shape/${props.SHAPE && props.SHAPE.toLowerCase()}-no.png`
                                                            )
                                                        }}
                                                        width="145px"
                                                    />
                                                ) : (
                                                    <img
                                                        className="img-fluid mr-2"
                                                        alt={props.SHAPE}
                                                        src={toAbsoluteUrl(
                                                            `/media/shape/${props.SHAPE && props.SHAPE.toLowerCase()}-no.png`
                                                        )}
                                                        onError={e => {
                                                            e.currentTarget.src = toAbsoluteUrl(
                                                                `/media/shape/noimage.jpg`
                                                            )
                                                        }}
                                                        width="145px"
                                                        style={{ backgroundColor: "#f4f4f4" }}
                                                    />
                                                )}
                                            </div>
                                            <div className="pl-2 p-0 text-left">
                                                <div className={`mb-1 ${props.diamond_type === "N" ? "natTab" : "labTab"}`}>
                                                    <span className={`${ props.diamond_type === "N" ? props.FANCY_COLOR === "fancy" ? "F" : "W" : props.FANCY_COLOR === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                                        {props.diamond_type === "N" || props.diamond_type === "W" ? props.FANCY_COLOR === "fancy"  ? "NF" : "N" : props.FANCY_COLOR === "fancy" ? "LF" : "L"}
                                                    </span>
                                                    <span className={`text-primary font-weight-bold small ml-1`}>
                                                        {props.diamond_type === "N" ? "N" : "L"}-{props.STOCK_ID}
                                                    </span>
                                                    {
                                                        hideCertificateNumber ?
                                                        <span className="text-primary font-weight-bold mx-2 small">{props.Lab}</span> :
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="text-primary font-weight-bold mx-2 small"
                                                            href={
                                                                props.LAB === "IGI"
                                                                    ? `https://www.igi.org/viewpdf.php?r=${props.CERTIFICATE_NO}`
                                                                    : props.LAB === "GIA"
                                                                    ? `https://www.gia.edu/report-check?reportno=${props.CERTIFICATE_NO}`
                                                                    : props.LAB === "HRD"
                                                                    ? `http://ws2.hrdantwerp.com/HRprops.CertificateService.WebAPI/certificate?certificateNumber=${props.CERTIFICATE_NO}`
                                                                    : props.LAB === "GCAL"
                                                                    ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.CERTIFICATE_NO}`
                                                                    : props.Certi_link
                                                            }
                                                        >

                                                            {props.LAB}-{props.CERTIFICATE_NO}
                                                        </a>
                                                    }                                                            
                                                </div>
                                                <div className="text-dark font-size-h6 mb-2 font-weight-bold d-block">
                                                    {props.SHAPE}{" "}
                                                    {parseFloat(props.CARAT).toFixed(2)}{" "}
                                                    {props.COLOR === "fancy" ? (
                                                        <>
                                                            {props.FANCY_COLOR}{" "}
                                                        </>
                                                    ) : (
                                                        <>{props.COLOR}{" "}</>
                                                    )}
                                                    {props.CLARITY}{" "}{props.CUT}{" "}{props.POLISH}{" "}{props.SYMMETRY}{" "}
                                                    {props.FLUORESCENCE}
                                                </div>
                                                <div >
                                                    {
                                                        !hidePrice && (                                                            
                                                            <div className="d-flex">
                                                                <div className="mr-4">
                                                                    <p className="text-muted font-weight-bold small mb-0 text-uppercase">{localStorage.getItem("user_currency")}/CT</p>
                                                                    <p className="text-dark font-size-h6 font-weight-bold">
                                                                        <span>{Currency()}</span>
                                                                        {numberWithCommas(props.PRICE_PER_CTS)}
                                                                    </p>
                                                                </div>
                                                                
                                                                <div className="">
                                                                    <p className="text-muted font-weight-bold small mb-0 text-uppercase">Total</p>
                                                                    <p className="text-dark font-size-h6 font-weight-bold">
                                                                        <span>{Currency(localStorage.getItem("user_currency"))}</span>
                                                                        {numberWithCommas(props.TOTAL_PRICE)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }                                                                                                        
                                                </div>
                                                <div>
                                                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                                                    <Link to={`/shortlist`} className="btn ml-2 btn-primary" > Go to cart </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </Modal.Body>
                            </Modal>
                        </>
                    )}
                </div>
            </div>
        </div>
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <MySnackbarContentWrapper
                onClose={handleClose}
                variant="error"
                message="You must start a session before adding stones to your cart"
            />
        </Snackbar>
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={successOpen}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <MySnackbarContentWrapper
                onClose={handleClose}
                variant="success"
                message="Your stone is added to cart"
            />
        </Snackbar>
    </>
    );
};
