import React from "react";

export default function Sorting(props) {
    // function capitalizeFirstLetter(string) {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }

    return (
        <div>
            {/* <span className="w-100 mr-2">{props.language.CCMODE_THEME1_GRID_SORTBY}: {props.sorting ? capitalizeFirstLetter(props.sorting.split('_')[0]) : ""}</span> */}
            <select
                className="custom-select border border-primary rounded-0"
                onChange={(e) => props.setSorting(e.target.value)}
                value={props.sorting || ""}
            >
                <option value="" disabled selected>
                    {props.language.CCMODE_THEME1_GRID_SORTBY}
                </option>
                <optgroup label="Carat">
                    <option selected={props.sorting === "carat_desc"} value="carat_desc">
                        High to Low
                    </option>
                    <option selected={props.sorting === "carat_asc"} value="carat_asc">
                        Low to High
                    </option>
                </optgroup>
                <optgroup label="Color">
                    <option selected={props.sorting === "color_desc"} value="color_desc">
                        Z to D
                    </option>
                    <option selected={props.sorting === "color_asc"} value="color_asc">
                        D to Z
                    </option>
                </optgroup>
                <optgroup label="Clarity">
                    <option selected={props.sorting === "clarity_desc"} value="clarity_desc">
                        I3 to FL
                    </option>
                    <option selected={props.sorting === "clarity_asc"} value="clarity_asc">
                        FL to I3
                    </option>
                </optgroup>
                {!props.hidePrice?<optgroup label="Price">
                    <option selected={props.sorting === "price_desc"} value="price_desc">
                        High to Low
                    </option>
                    <option selected={props.sorting === "price_asc"} value="price_asc">
                        Low to High
                    </option>
                </optgroup>:""}
            </select>
        </div>
    );
}
