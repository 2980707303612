import React, { useEffect } from "react"
import { makeStyles } from '@material-ui/core';
import {InputLabel} from '@material-ui/core';
import {MenuItem} from '@material-ui/core';
import { FormControl } from "@material-ui/core";
// import { GetCookies,SetCookies } from "../../../../_helpers/Cookies";
import { GetCookies,SetCookies } from "../../../_helpers/Cookies";
import {Select} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      borderColor: "#3699FF"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
export default function MultiLingual(){
    const classes = useStyles();
    const [language, setLanguage] = React.useState('en');
    const handleChange = (event) => {
        setLanguage(event.target.value)
        SetCookies("language",event.target.value)
        window.location.reload(false);

    }
    useEffect(() => {
        GetCookies("language").then(response => {
            // console.log(response,"LANGUAGECOOKIES")
            if(response){
                setLanguage(response)
            }
            else{
                setLanguage("en")
                SetCookies("language","en")
            }
        })
    },[])
    return(
        <div className="MultiLingual">
          <FormControl className={classes.formControl}>
            {/* <InputLabel id="MultiLingual">Language</InputLabel> */}
            <Select
              labelId="MultiLingual"
              id="MultiLingual"
              value={language}
              onChange={handleChange}
              variant="outlined"
              margin="dense"
            >
              <MenuItem value={"en"}>English</MenuItem>  
              <MenuItem value={"hi"}>हिंदी</MenuItem>
              <MenuItem value={"es"}>español</MenuItem>
              <MenuItem value={"it"}>italiano</MenuItem>
              <MenuItem value={"ge"}>Deutsch</MenuItem>
              <MenuItem value={"ch"}>粵語</MenuItem>
            </Select>
          </FormControl>
        </div>
    )
}