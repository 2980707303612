import React from "react";
import { Field } from "formik";

export const Percentage = (props) => {
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const validValue = value.match(/^\d*\.?\d{0,2}$/);
      if (validValue) {
        props.formik.setFieldValue(name, value);
      }
    };

    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6">{props.language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>{props.language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_TABLE} %</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Table_Prc"
                                        min="0"
                                        step="any"
                                        id="Min_Table_Prc"
                                        placeholder="Min"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Table_Prc"
                                        min="0"
                                        step="any"
                                        id="Max_Table_Prc"
                                        placeholder="Max"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>{props.language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_DEPTH} %</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Depth_Prc"
                                        min="0"
                                        step="any"
                                        id="Min_Depth_Prc"
                                        placeholder="Min"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Depth_Prc"
                                        min="0"
                                        step="any"
                                        id="Max_Depth_Prc"
                                        placeholder="Max"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>{props.language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_RATIO}</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Ratio"
                                        min="0"
                                        step="0.05"
                                        id="Min_Ratio"
                                        placeholder="From"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                    />
                                    {/* <div className="input-group-append"><span className="input-group-text">%</span></div> */}
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Ratio"
                                        min="0"
                                        step="0.05"
                                        id="Max_Ratio"
                                        placeholder="To"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                    />
                                    {/* <div className="input-group-append"><span className="input-group-text">%</span></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
