import React, { useEffect , useState } from "react";
import { Slider, TextField,makeStyles } from "@material-ui/core";
import { validateInput } from "../../../../utilities/inputValidation";
// import { Field } from "formik";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";

const useStyles = makeStyles({
    TextField: {
      width: 80,
      margin:0
    },
  });

export const CaratLabFancy = ({formik,labFancyData,language,location}) => {
    const classes = useStyles();
    const [minCarat, setMinCarat] = useState(location && location.Formik && typeof (location.Formik.minCarat) === "number"?location.Formik.minCarat:labFancyData.length !== 0 && labFancyData[0].lab_min_carat !== null ? labFancyData[0].lab_min_carat : 0);
    const [maxCarat, setMaxCarat] = useState(location && location.Formik && typeof (location.Formik.maxCarat) === "number"?location.Formik.maxCarat:labFancyData.length !== 0 && labFancyData[0].lab_max_carat !== null ? labFancyData[0].lab_max_carat : 30);
    const [caratRangeValue, setCaratRangeValue] = useState([location && location.Formik && typeof (location.Formik.minCarat) === "number"?location.Formik.minCarat:labFancyData.length !== 0 && labFancyData[0].lab_min_carat !== null ? labFancyData[0].lab_min_carat : 0, location && location.Formik && typeof (location.Formik.maxCarat) === "number"?location.Formik.maxCarat:labFancyData.length !== 0 && labFancyData[0].lab_max_carat !== null ? labFancyData[0].lab_max_carat : 30]);
    const minCaratRange = labFancyData.length !== 0 && labFancyData[0].lab_min_carat !== null ? labFancyData[0].lab_min_carat : 0;
    const maxCaratRange = labFancyData.length !== 0 && labFancyData[0].lab_max_carat !== null ? labFancyData[0].lab_max_carat : 30;

    useEffect(() => {
        formik.setFieldValue("minCarat", minCarat);
        formik.setFieldValue("maxCarat", maxCarat);
    }, []);

    const handleCaratRangeChange = (event, newValue) => {
        setMinCarat(newValue[0]);
        setMaxCarat(newValue[1]);
        setCaratRangeValue(newValue);
        // formik.setFieldValue("minCarat", newValue[0]);
        // formik.setFieldValue("maxCarat", newValue[1]);
    };

    const handleCaratRangeChangeCommitted = (event, newValue) => {
        formik.setFieldValue("minCarat", newValue[0]);
        formik.setFieldValue("maxCarat", newValue[1]);
    };

    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_CARAT}</div>
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-md-12">
                            <Slider
                                getAriaLabel={() => "Carat Range"}
                                value={caratRangeValue}
                                onChangeCommitted={handleCaratRangeChangeCommitted}
                                onChange={handleCaratRangeChange}
                                step={0.01}
                                valueLabelDisplay="auto"
                                // getAriaValueText={caratRangeValue}
                                min={minCaratRange}
                                max={maxCaratRange}
                                color="secondary"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                name="minCarat"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={caratRangeValue[0]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    step: parseFloat(caratRangeValue[0]) <= 0.99?0.05:0.10,
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    if(validateInput(e.target.value)) {
                                        const value = Number(e.target.value);
                                        setMinCarat(value);
                                        setCaratRangeValue([value, caratRangeValue[1]]);
                                        formik.setFieldValue("minCarat", value);
                                    }                                    
                                }}
                            />
                                
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                variant="outlined"
                                name="maxCarat"
                                InputLabelProps={{ shrink: true }}
                                value={ caratRangeValue[1]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    step: parseFloat(caratRangeValue[1]) <= 0.99?0.05:0.10,
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    if(validateInput(e.target.value)) {
                                        const value = Number(e.target.value);
                                        setMaxCarat(value);
                                        setCaratRangeValue([caratRangeValue[0], value]);
                                        formik.setFieldValue("maxCarat", value);
                                    }                                    
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
