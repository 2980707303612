import React,{useMemo} from "react";
import { Field } from "formik";

export const PriceLabFancy = (props) => {
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    function Currency(){
        if (user.Currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (user.Currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (user.Currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (user.Currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (user.Currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (user.Currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }
    }

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      const validValue = value.match(/^\d*\.?\d{0,2}$/);
      if (validValue) {
        props.formik.setFieldValue(name, value);
      }
    };

    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6">{props.language.CCMODE_THEME1_SEARCHDIAMOND_PRICE}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        {/* <div className="col-md-6 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>{Currency()}/CT</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Rate"
                                        min="0"
                                        id="Min_Rate"
                                        placeholder="From"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">{user.Currency}</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Rate"
                                        min="0"
                                        id="Max_Rate"
                                        placeholder="To"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">{user.Currency}</span></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>Price</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Price"
                                        min="0"
                                        step={props.formik.values?.Min_Price <= 9999.99?100:1000}
                                        id="Min_Price"
                                        placeholder="From"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">{user.Currency}</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Price"
                                        min="0"
                                        step={props.formik.values?.Max_Price <= 9999.99?100:1000}
                                        id="Max_Price"
                                        placeholder="To"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">{user.Currency}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
