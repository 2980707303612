/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useContext,useState,useEffect,useMemo,useRef} from "react";
import { useHistory,useLocation } from "react-router-dom";
import { Modal, Button,Collapse,OverlayTrigger,Tooltip} from "react-bootstrap";
import { FiPlusCircle,FiMinusCircle } from 'react-icons/fi';
import { FaList,FaPlus,FaMinus } from 'react-icons/fa';
import { BsFillGridFill } from 'react-icons/bs';
import { Formik, Form, } from "formik";
import { StoreDiamondDB } from "../../../_helpers/IndexedDB";
import { GridResult } from "../searchResultTheme3/Grid";
import { ListResult } from "../searchResultTheme3/ListResult";
// import { BasicLab } from "../../searchDiamComponent/BasicLab";
// import { Basic } from "../../searchDiamComponent/Basic";
// import { Preference1 } from "../../searchDiamComponent/Preference1";
import { Basic } from "./searchComponent/Basic";
import { Advanced } from "./searchComponent/Advanced";
import PropTypes from "prop-types";
import { IoMdRefresh } from "react-icons/io";
// import axios from "axios";
// import {SEARCH_LOG,SEARCH_COUNT,FETCH_DIAMOND} from "../../../../env_config"
import  DiamondContext  from "./SearchDiamondContext";
import { Paper,Tabs,Tab,Typography} from "@material-ui/core";
import { SelectSessionModal } from "../../selectSession/selectSession"
import Sorting from "./Sorting";
import { useSelector } from "react-redux";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const SearchDiamondTheme3 = ({ruleData,naturalData,naturalFancyData,labData,labFancyData,language}) => {
    const diamondsearch = useContext(DiamondContext)
    const [sessModalShow, setSessModalShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [advOpen,setAdvOpen] = useState(false)
    const [filterOpen,setFilterOpen] = useState(true)
    const [searchdata,setSearchData] = useState([])
    const [diamond_type,setDiamondType] = useState()
    const [custompage,setCustomPage] = useState(1)
    const [loader,setLoader] = useState(false)
    const [sorting,setSorting] = useState("")
    const [fancy,setFancy] = useState(naturalData.length===0 && naturalFancyData.length !== 0 ? true : false)
    const [fancyLab,setFancyLab] = useState(labData.length===0 && labFancyData.length !== 0 ? true : false)
    const location = useLocation()
    const {
        hideCertificateNumber,
        hideEstimatedShippingDays,
        hidePrice,
      } = useSelector((state) => {
        return state.CcModeSettingReducer;
      });   
    console.log(location.state,"STATE")
    useEffect(() => {
        if(naturalData && naturalData.length && naturalData[0].status === 0){
            setFancy(true)
        }
        if(labData && labData.length && labData[0].status === 0){
            setFancyLab(true)
        }
    },[])

    const [openLoader, setOpenLoader] = useState(false);
    const [shortlist,setShortlist] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false);
    const [zeroSuccessOpen, setZeroSuccessOpen] = useState(false);
    const history = useHistory();
    // const { user } = useSelector((state) => state.auth);
    const formikRef = useRef();
    const [user, setUser] = useState([]);
    const [value, setValue] = useState(window.glob||0);
    const [resultValue, setResultValue] = useState(0);
    // const [id, setID] = useState([]);
    const [natData, setNatData] = useState([]);
    const [stoneCount, setStoneCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const id = useMemo(()=>localStorage.getItem("C_id"),[])

    function handleTabChange(event, newValue) {
        setResultValue(newValue);
    }
    
    function handleClose(event, reason) {
        setOpen(false);
        setSessModalShow(false);
        setZeroSuccessOpen(false);
    }

    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(!openLoader);
    };

    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
    };



    function handelSession(){
        setSessModalShow(true)
    }
    
    useEffect(() =>{
        const userData = localStorage.getItem("userData");
        setUser(JSON.parse(userData))
            // setDiamondType("N")
            if((naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0)){
                setDiamondType("N")
            }
            else if((labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)){
                setDiamondType("L")
            }
            // setDiamondType("L")
            if((naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0)){
                setDiamondType("N")
            }
            else if((labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)){
                setDiamondType("L")
            }

        if (id === "-2" ){
            setTimeout(() => {
                handelSession()
        }, 500);
        }
   }, [id]);

   useEffect(() => {
    if(location.state && location.state.FinalObject){
        if(location.state.diamond_type === "N"){
            setDiamondType(location.state.diamond_type)
            if(location.state.FinalObject.fancy_color_diamond){
                setFancy(true)
            }
            else{
                setFancy(false)
            }
        }
        if(location.state.diamond_type === "L"){
            setDiamondType(location.state.diamond_type)
            if(location.state.FinalObject.fancy_color_diamond){
                setFancyLab(true)
            }
            else{
                setFancyLab(false)
            }
        }
    }
    if(location.state && location.state.sorting){
        setSorting(location.state.sorting)        
    }
   },[location.state])

    function handleChange(event, newValue) {
        window.glob = newValue
        setDiamondType(newValue);
    }
   
    const [finalFetchData, setFinalFetchData] = useState([]);
    useEffect(
        () => window.localStorage.setItem("fetchData", finalFetchData),
        [finalFetchData]
    );
    useEffect(
        () => window.localStorage.setItem("stoneCount", stoneCount),
        [stoneCount]
    );
    const ResetForm = () => {
        formikRef.current?.resetForm()
        window.history.replaceState({}, '') 
        window.location.reload(false);
    }
    const ResetFormTab = () => {
        formikRef.current?.resetForm()
        window.history.replaceState({}, '')
    }
    return (
    (user? 
        <div className="container theme3">
            <div className={`row ${diamond_type==="N"?"natTab":"labTab"}`}>
                <div className="col-12">
                    <div className="card card-custom card-sticky gutter-b">
                        <div
                            className="card-header minHeight justify-content-between align-items-center p-0"
                            style={{
                                height: " 49px!important",
                            }}
                        >
                            <Paper position="static">
                                <Tabs value={diamond_type} onChange={handleChange}>
                                    {(naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0) ? <Tab label={language.CCMODE_THEME1_SEARCHDIAMOND_NATURAL} value="N" onClick={() => ResetFormTab()}/>:""}
                                    {(labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)  ? <Tab label={language.CCMODE_THEME1_SEARCHDIAMOND_LABGROWN} value="L" onClick={() => ResetFormTab()}/>:""}
                                </Tabs>
                            </Paper>
                            <Button
                                onClick={() => setFilterOpen(!filterOpen)}
                                aria-controls="filters"
                                aria-expanded={filterOpen}
                                className="btn-light-primary btn-icon btn-sm font-weight-bolder mr-5"
                            >
                                {!filterOpen ? <FaPlus className="font-size-lg" /> : <FaMinus className="font-size-lg" />} 
                            </Button>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <Collapse in={filterOpen}>
                                        <div id="filters">
                                            <Formik
                                                innerRef={formikRef}
                                                enableReinitialize={true}
                                                initialValues={location.state && location.state.Formik || {}}
                                                onSubmit={async (values, { setSubmitting }) => {
                                                    // enableLoading();
                                                    Object.keys(values).forEach((key) => {
                                                        if ( values[key] === "" || values[key] === null || values[key].length === 0 ) {
                                                            delete values[key];
                                                        }
                                                    });
                                                }}
                                            >
                                                {(formik, isSubmitting, handleSubmit) => (
                                                    <>
                                                        <Form>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <Basic formik={formik} setSearchData={setSearchData} diamond_type={diamond_type} custompage={custompage} setLoader={setLoader} sorting={sorting} ruleData={ruleData} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} setFancy={setFancy} fancy={fancy} setFancyLab={setFancyLab} fancyLab={fancyLab} shortlist={shortlist} language={language} location={location.state}  setStoneCount={setStoneCount} setCustomPage={setCustomPage} searchdata={searchdata} stoneCount={stoneCount}/>
                                                                </div>
                                                                <div className="col-12 mt-10 text-center position-relative">
                                                                    <div className="btn-seprator">
                                                                        <Button
                                                                            onClick={() => setAdvOpen(!advOpen)}
                                                                            aria-controls="advancedFilter"
                                                                            aria-expanded={advOpen}
                                                                            className="btn-primary btn-sm font-weight-bolder advanceFilterBtn"
                                                                        >
                                                                            {language.CCMODE_THEME3_SEARCHDIAMOND_ADDITIONAL_FILTER} {advOpen ? <FiMinusCircle className="ml-2 font-size-lg" /> : <FiPlusCircle className="ml-2 font-size-lg" />} 
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 ">
                                                                    <Collapse in={advOpen}>
                                                                        <div className="mt-5" id="advancedFilter">
                                                                            <Advanced formik={formik} setSearchData={setSearchData} diamond_type={diamond_type} custompage={custompage} setLoader={setLoader} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} fancy={fancy} fancyLab={fancyLab} language={language} location={location.state}/>
                                                                        </div>
                                                                    </Collapse>
                                                                </div>
                                                                
                                                            </div>
                                                        </Form>
                                                    </>
                                                )}
                                            </Formik>
                                        </div>
                                    </Collapse>
                                </div>
                                <div className="col-12 bg-gray-100 d-flex justify-content-between py-3 mt-10">
                            {/* <div>
                                <div className="custom-result-box">
                                    Result: {searchdata && searchdata.total?searchdata.total:0}
                                </div>
                            </div> */}
                            {/* Sorting Start*/}
                                <Sorting setSorting={setSorting} sorting={sorting} language={language} hidePrice={hidePrice}/>
                                <div className="custom-result-box mr-15">
                                {language.TOTAL_STONE_COUNT}: {stoneCount}
                                </div>
                            {/* Sorting End */}
                            <div className="search-result" style={{ display: 'flex', alignItems: 'center' }}>
                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip">Reset All</Tooltip>}
                                        >
                                            <IoMdRefresh
                                                className="font-size-h4 cursor-pointer"
                                                onClick={() => ResetForm()}
                                            />
                                        </OverlayTrigger>
                                <Tabs value={resultValue} indicatorColor="secondary" textColor="secondary" onChange={handleTabChange}>
                                    
                                    <Tab icon={<BsFillGridFill className="font-size-h5" />}  aria-label="grid"/>
                                    <Tab icon={<FaList className="font-size-h5" />} aria-label="list"/>
                                </Tabs>
                                {/* <Paper position="static" className="shadow-none">
                                </Paper> */}
                            </div>
                        </div>
                        <div className="col-12 search-result-list">
                            {resultValue === 0 && (
                                <TabContainer className="p-0">
                                    {/* <ListResult searchdata={searchdata} custompage={custompage} setCustomPage={setCustomPage}/> */}
                                    <GridResult searchdata={searchdata} custompage={custompage} setCustomPage={setCustomPage} loader={loader} setShortlist={setShortlist} shortlist={shortlist} language={language}/>
                                </TabContainer>
                            )}
                            {resultValue === 1 && (
                                <TabContainer>
                                    {/* <Grid searchdata={searchdata} custompage={custompage} setCustomPage={setCustomPage}/> */}
                                    <ListResult searchdata={searchdata} custompage={custompage} setCustomPage={setCustomPage} loader={loader} setShortlist={setShortlist} shortlist={shortlist} language={language} stoneCount={stoneCount}/>
                                </TabContainer>
                            )}
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 d-none"></div>
            </div>
            <Modal
                show={sessModalShow}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body >
                    <SelectSessionModal setOpen={setOpen} setSessModalShow={setSessModalShow} language={language}/>
                </Modal.Body>
            </Modal>
        </div>
    : "")
    );
};
