/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link,useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import axios from "axios";
import {FETCH_CURRENCY,FETCH_COUNSUMER_LIST,NODE_API_URL} from "./../../../../../env_config"
import { IoMdTimer } from "react-icons/io";

export function UserProfileDropdown(props) {
  // const { user } = useSelector((state) => state.auth);
  const [name, setName] = React.useState([]);
  const [curRate, setCurRate] = React.useState([]);
  const userData = useMemo(()=>localStorage.getItem("userData"),[])
  const user = JSON.parse(userData)
  const history = useHistory();
  const id = useMemo(()=>localStorage.getItem("C_id"),[])
// console.log(user)
  React.useEffect(() => {
       
    const getShortListData = async () => {
        axios({
            method: 'GET',
            url: `${FETCH_COUNSUMER_LIST}/${user.CustomerId}`,
            headers: { 
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
                },
            })
            .then(function (res) {
              // alert('Data added successfully');  
              let name = res.data.filter((ele)=>{if(ele.ConsumerId==id)return ele})
                if(name.length?name:'')
                setName(name[0].ListName);
            })
            .catch(function (res) {
                console.log(res)
            });
        };
        setTimeout(() => {
            getShortListData();
        })
    }, [id]);

   
    function getCurrency(){
      const currencyData = { "Currency": user.Currency };
      console.log(currencyData,"currencyData")
      axios({
        method: "POST",
        url: FETCH_CURRENCY,
        headers: {
            "Content-Type": "application/json",
        },
            data: currencyData,
        })
        .then(function(res) {
          setCurRate(res.data.currency)
        })
        .catch(function(res) {
            console.error(res);
        });
    }

  function selectSession (){
    window.localStorage.setItem("C_id", -2)
    setTimeout(() => {
      if (document.location.pathname == '/searchdiamond'){
          setTimeout(() => {
            window.location.reload(true)
        }, 400);
      }
        history.push({  pathname: '/searchdiamond'})
    }, 500);
  }

  function endSession (){
    const finalData = {
      ConsumerId: id,
      CustomerId: user.CustomerId,
      Action:"Session Ended"
    }
    axios({
        method: "POST",
        url: `${NODE_API_URL}/ccmode/storeWhiteLabelConsumerDetails`,
        headers: { "Content-Type": "application/json" },
        data: finalData,
    }).then(res => {
        // console.log(res,"RESPONSEEE")
    }).catch(err => {
        
    })
    window.localStorage.setItem("C_id", -1)
    setTimeout(() => {
      setTimeout(() => {
        window.location.reload(true)
    }, 100);
        history.push({  pathname: '/searchdiamond'})
    }, 500);
  }

  function currencyIcon(){
    if (user.Currency === "USD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (user.Currency === "INR"){
        const  currencyIcon = "₹"
        return currencyIcon
    } else if (user.Currency === "HKD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (user.Currency === "AUD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (user.Currency === "EUR"){
        const  currencyIcon = "€"
        return currencyIcon
    } else if (user.Currency === "CAD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (user.Currency === "CNY"){
        const  currencyIcon = "¥"
        return currencyIcon
    } else if (user.Currency === "GBP"){
        const  currencyIcon = "£"
        return currencyIcon
    } else if (user.Currency === "NZD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (user.Currency === "JPY"){
        const  currencyIcon = "¥"
        return currencyIcon
    } else if (user.Currency === "CHF"){
        const  currencyIcon = "₣"
        return currencyIcon
    }
  }

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);
//   if(user === undefined ){
//     return
// }
  return (
    <Dropdown drop="down" alignRight>
     {user? <>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
           onClick={() => getCurrency()}
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <span className="symbol symbol-35 symbol-light-primary symbol-circle">
              <span className="symbol-label font-size-h5 font-weight-bold">
                {/* {user.CompanyName[0]} */}
                <i className="flaticon2-user text-primary"></i>
              </span>
              {id === "-1" || id === "-2" || id === "" ? (
                <>
                  <i className="symbol-badge symbol-badge-bottom bg-danger"></i>
                </>
              ) : (
                <>
                  <i className="symbol-badge symbol-badge-bottom bg-success"></i>
                </>
              )}
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-sm">
          <div className="navi navi-spacer-x-0 pt-5">
            <div className="navi-item mb-4 text-center px-8">
                <span className="font-size-h6 font-weight-bold"> {user.Currency} - {currencyIcon()} ({Number(curRate).toFixed(2)})</span>
            </div>
            <div className="separator separator-solid"></div>
            <div className="navi-item px-8">
              <div className="navi-link">
                {/* <div className="navi-icon mr-2">
                 <IoMdTimer className="text-warning" />
                </div> */}
                <div className="navi-text text-center">
                  <div className="small text-muted font-weight-bold"> TimeZone</div>
                  <span className="font-size-h6 font-weight-bold"> {user.timeZone ? user.timeZone : "Etc/GMT"}</span>
                </div>
              </div>
            </div>
            
            <div className="separator separator-solid"></div>
          <Link to="/searchdiamond" className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon-search text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">{props.language.CCMODE_USERDROPDOWN_SEARCHDIAMONDS}</div>
              </div>
            </div>
          </Link>
          {id === "-1" || id === "-2" || id === "" ? (
              <>
                <div  onClick={() => selectSession()} className="navi-item px-8 cursor-pointer">
                  <div className="navi-link" >
                    <div className="navi-icon mr-2">
                      <i className="flaticon2-list-1 text-warning"></i>
                    </div>
                    <div className="navi-text">
                      <div className="font-weight-bold">{props.language.CCMODE_USERDROPDOWN_STARTSESSION}</div>
                    </div>
                  </div>
                </div>
                  
              </>
          ) : (
              <>
                  <div  onClick={() => endSession()} className="navi-item px-8 cursor-pointer">
                    <div className="navi-link" >
                      <div className="navi-icon mr-2">
                        <i className="flaticon2-delete text-danger"></i>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold">{props.language.CCMODE_USERDROPDOWN_ENDSESSION}</div>
                        <span className=" font-weight-bold text-nowrap text-success">({name})</span>
                      </div>
                    </div>
                  </div>
              </>
          )}          
            <Link to="/logout" className="navi-item px-8 mb-5">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon-logout text-danger"></i>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold"> {props.language.CCMODE_USERDROPDOWN_SIGNOUT}</div>
                </div>
              </div>
            </Link> 
          </div>
        </Dropdown.Menu>
      </>:""}
    </Dropdown>
  );
}
