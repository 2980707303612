import React, { Suspense } from "react";
import { Redirect, Switch, } from "react-router-dom";
// import { useSelector } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { ShortListPage } from "./pages/ShortListPage";
import "../assets/css/BackendStyle.css"
import { SearchResultTheme1,SearchDiamondTheme1,GridCardTheme1,ProductDetailMainTheme1 } from "../_metronic/_partials/theme1";
import { SearchResultTheme2,SearchDiamondTheme2,GridCardTheme2,ProductDetailMainTheme2 } from "../_metronic/_partials/theme2";
import { SearchResultTheme3,SearchDiamondTheme3,GridCardTheme3,ProductDetailMainTheme3 } from "../_metronic/_partials/theme3";
import IdleTimerContainer from "./IdleTimerContainer";
import { Loader } from "../_metronic/_partials/Loader";
// import * as auth from "../Auth";


export default function BasePage({theme,user,ruleData,naturalData,naturalFancyData,labData,labFancyData,language,themeData}) {
 
  // const { user } = useSelector((state) => state.auth);
  // const [theme , setTheme] = useState('theme1');
  // const [user, setUser] = React.useState([]);
  // const [id, setID] = React.useState();

  // console.log("theme",theme)
  // console.log("ruleData",ruleData)
  // console.log(language,"language")
  return (
    <>
      <IdleTimerContainer /> 
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/searchdiamond" />  
            {/* new */}
            {theme === 'theme1' ? 
                <>
                  {/* theme1  */}
                  <ContentRoute path="/search-result" component={() => <SearchResultTheme1 language={language}/>} />
                  <ContentRoute path="/grid" component={() => <GridCardTheme1 language={language}/>} />
                  <ContentRoute path="/details" component={() => <ProductDetailMainTheme1 language={language}/>} />
                  <ContentRoute path="/shortlist" component={() => <ShortListPage language={language} themeData={themeData}/>} />
                  <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme1 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language}/>}/>
                </>
            : theme === 'theme2' ? 
              <>
                {/* theme2  */}
                <ContentRoute path="/search-result" component={SearchResultTheme2} />
                <ContentRoute path="/grid" component={GridCardTheme2} />
                <ContentRoute path="/details" component={() => <ProductDetailMainTheme2 language={language}/>} />
                <ContentRoute path="/shortlist" component={() => <ShortListPage language={language} themeData={themeData}/>} />
                <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme2 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language} />} />
              </> 
            : theme === 'theme3' ?
              <>
                {/* theme3  */}
                <ContentRoute path="/search-result" component={SearchResultTheme3} />
                <ContentRoute path="/grid" component={GridCardTheme3} />
                <ContentRoute path="/details" component={() => <ProductDetailMainTheme3 language={language}/>} />
                <ContentRoute path="/shortlist" component={() => <ShortListPage language={language} themeData={themeData}/>} />
                <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme3 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language} />} />
              </> 
            :
                <>
                  <ContentRoute path="/searchdiamond" component={Loader} />
                </>
              }
            <Redirect to="error/error-v1" />
          </Switch>
        </Suspense>
    </>

  );
}
