import React, {  useState , useEffect} from "react";
import { GridCardContent } from "./card/gridCardContent";
import {   useHistory,useLocation } from 'react-router-dom';
import { FaList,FaSearch } from "react-icons/fa";
import { GetDiamondDB } from "../../../_helpers/IndexedDB";
// import axios from "axios";
export const GridCardTheme3 = () => {
    const [count, setCount] = React.useState([]);
    const [start,setStart]=React.useState(0);
    const [page,setPage] = React.useState(12);
    const [actData, setactData] = React.useState([]);
    const history = useHistory();
    const location = useLocation();
    // const natData = diamondsearch.value
    const [natData,setNatData] = useState([])
    window.searchData = natData;
    React.useEffect(() => {
        const stoneCount = localStorage.getItem("stoneCount");
        setCount(stoneCount)
        GetDiamondDB().then(response => {
            setNatData(response)
        })
    }, []);

    useEffect(()=>{
        if(natData.length === 0) return
    
       setTimeout(()=>{
        handleInfiniteScrolling();
       },300)
       },[natData])
        const handleInfiniteScrolling =()=>{
    
            try{
               
                 if((window.innerHeight + document.documentElement.scrollTop + 1 >=  document.documentElement.scrollHeight) && natData.length > 0){
                    setStart((prev)=>prev+12)
                    setPage((prev)=>prev+12)
                    let temp =natData.slice(start,page)
                    setactData([...actData, ...temp])
                    
                }
            }catch(err){
            }
        }
            useEffect(()=>{
                 window.addEventListener("scroll",handleInfiniteScrolling);
                 return()=>window.removeEventListener("scroll",handleInfiniteScrolling)
            },)
   
    const natList = () =>{
        history.push({  pathname: '/search-result', state:natData})
    }
    const backBtn = () =>{
        history.push({  pathname: '/searchdiamond'})
    }
   

    return (
        <>
            {/* <InfiniteScroll
                dataLength={items.length} //This is important field to render the next data
                next={fetchData}
                hasMore={noMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            > */}
                <div className={`container theme3`}>
                   <div className="card">
                        <div className="card-header d-flex justify-content-between py-4 align-items-center sticky-top" style={{top:"65px"}}>
                            <div className="card-title m-0">
                                <h3 className="font-weight-bolder">Stock List <span className="text-primary">({count}  stones found)</span></h3>
                            </div>
                            <div className="card-toolbar">
                                {/* <button className="btn btn-primary btn-sm btn-square mr-3" onClick={selectSession}><FaList/> selectSession</button> */}
                                <button className="btn btn-primary btn-sm btn-square mr-3" onClick={natList}><FaList/> List</button>
                                <button className="btn btn-danger btn-sm btn-square" onClick={backBtn}><FaSearch/> Go To Search</button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row m-0 mt-5">
                                {/* {JSON.stringify(natData)} */}
                                {actData.map((val) => {
                                    return (
                                        <GridCardContent
                                            key={val.ProductId}
                                            {...val}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                   </div>
                </div>
            {/* </InfiniteScroll> */}
        </>
    );
};
