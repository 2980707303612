import React, {useMemo,useState} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { useDispatch, useSelector } from "react-redux";

// import StockAPI from "./StockAPI.json"
import { searchResultColumn } from './List/Columns'; 
// import GlobleFilter from './GlobleFilter';
import { useHistory } from "react-router-dom";
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaArrowAltCircleRight, FaArrowCircleDown } from "react-icons/fa";
import { makeStyles, Snackbar, IconButton, SnackbarContent} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
// import { CheckBox } from './CheckBox';
// import { Dropdown, Modal } from 'react-bootstrap';
// import { FaEllipsisV } from 'react-icons/fa'
// import  ExpandedDetails from "./ExpandedDetails"
import { PopUp } from '../../popup/popUp';
import Loader from '../searchDiamond/Loader';
import ListSkeleton from '../../ListSkeleton';
export const ListResult = (props) => {

    const data = useMemo(()=> props.searchdata.data || [],[props.searchdata.data])
    // const data = useMemo(()=> [StockAPI],[])
    const id = useMemo(()=>localStorage.getItem("C_id"),[])
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const {
        hideCertificateNumber,
        hideEstimatedShippingDays,
        hidePrice,
      } = useSelector((state) => {
        return state.CcModeSettingReducer;
      });  
    const columns = useMemo(() => searchResultColumn(user,props.language,hideCertificateNumber,hidePrice,hideEstimatedShippingDays), [props.searchdata,hideCertificateNumber,hidePrice,hideEstimatedShippingDays]);
    function handelDetails(row){
        history.push({  pathname: '/details', state:{
            StockID:row.row.original.STOCK_ID,
            diamond_type:row.row.original.COLOR?row.row.original.diamond_type:row.row.original.diamond_type + "F"
        }})
        window.scrollTo(0, 0)
    }   
    function handleClose(event, reason) {
        setOpen(false);
        // successOpen(false);
        // setCustomizeTable(false)/
        // setShowWithoutModel(false);
    }
    //-------------------------------------------------------------------------

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const classes = useStyles();
    const [successOpen, setSuccessOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    function withoutSession() {
        setOpen(true);
    }
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        // state:{ globalFilter, pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: props.stoneCount }
    },
    useGlobalFilter,
    // useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    
                    {
                        Header: props.language.CCMODE_THEME1_LIST_ACTION,
                        className: "text-center align-middle text-nowrap",
                        Cell: (row) => {
                            return <>
                                <button className="btn btn-secondary btn-sm px-8 btn-square font-weight-bold" onClick={() => handelDetails(row)}>Details</button>
                                {id === "-1" || id === "-2" || id === "" ? (
                                    <>
                                        <button
                                            onClick={(e) => withoutSession()}
                                            className={`btn btn-square btn-sm font-weight-bold ${row.Shortlisted === 1 ? "btn-info" : "btn-danger"}`}
                                        >
                                            {row.Shortlisted === 1 ? "Shortlisted" : "shortlist"}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                    <PopUp ProductId={row.row.original.STOCK_ID} WLDiamondType={row.row.original.diamond_type} C_Weight={row.row.original.CARAT} C_Fluorescence={row.row.original.FLUORESCENCE} Symmetry={row.row.original.SYMMETRY} C_Polish={row.row.original.POLISH} C_Cut={row.row.original.CUT} C_Clarity={row.row.original.CLARITY} C_Color={row.row.original.COLOR} C_Shape={row.row.original.SHAPE}  CERTIFICATE_NO={row.row.original.CERTIFICATE_NO} OurRate={row.row.original.OurRate} OurPrice={row.row.original.OurPrice} MarkUpRate={row.row.original.PRICE_PER_CTS} MarkUpPrice={row.row.original.TOTAL_PRICE} Shortlisted={row.row.original.Shortlisted} calculateprice={row.row.original.calculateprice} PRICE_PER_CTS={row.row.original.PRICE_PER_CTS} TOTAL_PRICE={row.row.original.TOTAL_PRICE} CURRENCY_RATE={row.row.original.CURRENCY_RATE} CURRENCY={row.row.original.CURRENCY} diamond_type={row.row.original.diamond_type} STOCK_ID={row.row.original.STOCK_ID} data={data} taxvalue={row.row.original.taxvalue} setShortlist={props.setShortlist} shortlist={props.shortlist} language={props.language} original={row.row.original} {...row.row.original}/>
                                        {/* <MakePopup values={d} /> */}
                                    </>
                                )}
                            </>;
                        },
                    },
                    ...columns,
                    
                ]
            })
        }
    )
    return (
        <>
        {/* <ListSkeleton /> */}
        <div className='row mt-5'>
            <div className='col-12'>
                <div className="table-responsive w-100">
                    <table className="table table-striped table-hover custom_datatable" {...getTableProps()}>
                        <thead className="bg-gray-100">
                            {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(
                                        (column, i) => (
                                            <th key={i} className="text-center align-middle text-nowrap">
                                                {column.render("Header")}
                                                {/* <span className="text-secondary">
                                                    {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                </span> */}
                                            </th>
                                        )
                                    )}
                                </tr>
                            ))}
                        </thead>
                       {props.loader && props.custompage === 1?<ListSkeleton/>:""}
                        {page.length?<tbody {...getTableBodyProps()}>
                            {page.map((row,i) => {
                                prepareRow(row)
                                return(
                                    <React.Fragment key={i}>
                                        <tr {...row.getRowProps()} key={row.id}>
                                            {row.cells.map((cell,i) => {
                                                return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                        {/* {row.isExpanded ? (
                                            <tr>
                                                <td className="p-0" colSpan={visibleColumns.length}>
                                                    {renderRowSubComponent({ row })} 
                                                </td>
                                            </tr>
                                        ) : null} */}
                                    </React.Fragment>
                                )
                            })}
                        </tbody>:<tbody><tr><td className="text-center align-middle" colSpan={"100%"}>No Record Found</td></tr></tbody>}
                        {data.length % 100?<div className="text-center mt-5">No More Records</div>:""}
                        {props.loader && props.custompage !== 1?<ListSkeleton/>:""}
                    </table>
                </div>
            </div>
        </div>
        <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message="You must start a session before adding stones to your cart"
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message="Your stone is added to cart"
                />
            </Snackbar>
        </>
    )
}