/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState,useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { GetCookies } from "../_metronic/_helpers/Cookies";
import axios from "axios";
import { LOGO_BASE_URL, NODE_API_URL } from "../env_config";
import languages from "../_metronic/layout/components/extras/languages.json" 
import { setCcModeSetting } from "../redux/commonRedux/common.actions";
export function Routes() {
    const dispatch = useDispatch();
    const [theme, setTheme] = useState("loader");
    const [themeData, setThemeData] = useState([]);
    const [ruleData, setRuleData] = useState([]);
    const [naturalData, setNaturalData] = useState([]);
    const [naturalFancyData, setNaturalFancyData] = useState([]);
    const [labData, setLabData] = useState([]);
    const [labFancyData, setLabFancyData] = useState([]);
    const [user, setUser] = useState([]);
    const [language,setLanguage] = React.useState("en")
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    useEffect(() => {
      if (isAuthorized){
        GetCookies("resData").then(response => {
          if(response){
            let userData = JSON.parse(response)
            setUser(JSON.parse(response));
            axios({
              method: "POST",
              url: `${NODE_API_URL}/fetchCCMOdeSetting`,
              // url: `https://api.staging.global.diamondsupload.com/INTL/fetchCCModeSetting`,
              headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
              },
              data: {
                CustomerId:userData.CustomerId
              }
            })
            .then(function(res) {
              if(res && res.data && res.data.length){
                setThemeData(res.data[0])
                setTheme(res.data[0].Theme)
                if(res.data[0] && res.data[0].AllowLogin.data[0] === 0){
                    localStorage.clear();
                    window.location.replace("/")
                }
                dispatch(
                  setCcModeSetting({
                    hideCertificateNumber: res.data[0].hideCertificateNumber,
                    hideEstimatedShippingDays:
                      res.data[0].hideEstimatedShippingDays,
                    hidePrice: res.data[0].hidePrice,
                    displayName: res.data[0].displayName,
                    displaybuybtn:res.data[0].displaybuybtn
                  })
                );
                // console.log("here1");
                // console.log(res.data[0]);
              }
              else{
                localStorage.clear();
                window.location.replace("/")
              }
            })
            .catch((res) => {
                console.warn(res);
            });

            // for rules 

            axios({
              method: "POST",
              url: `${NODE_API_URL}/ccmode/fetchCCModerule`,
              // url: `https://api.staging.global.diamondsupload.com/INTL/fetchCCModeSetting`,
              headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/json",
              },
              data: {
                user_id:userData.CustomerId
              }
            })
            .then(function(res) {
              if(res && res.data){
                setRuleData(res.data.data)
                let checkstatus = res.data.data.map(value => value.status).every(element => element === 0)
                if(checkstatus){
                  localStorage.clear();
                  window.location.replace("/")
                }
                // setTheme(res.data[0].Theme)
                // console.log(res.data.data);
                // console.log("here2");
                // const resNaturalData = res.data.data.find(element => element.naturaldiamond === 1)
                // const resNaturalfancyData = res.data.data.find(element => element.naturalfancydiamond === 1)
                // const resLabData = res.data.data.find(element => element.labdiamond === 1)
                // const resLabFancyData = res.data.data.find(element => element.labfancydiamond === 1)

                setNaturalData(
                    res.data.data.find(element => element.naturaldiamond === 1) !== undefined 
                    ? [res.data.data.find(element => element.naturaldiamond === 1) ]
                    : []
                  )

                setNaturalFancyData(
                    res.data.data.find(element => element.naturalfancydiamond === 1) !== undefined 
                    ? [res.data.data.find(element => element.naturalfancydiamond === 1)] 
                    : []
                  )

                setLabData(
                    res.data.data.find(element => element.labdiamond === 1) !== undefined ? 
                    [res.data.data.find(element => element.labdiamond === 1)] 
                    : []
                  )

                setLabFancyData(
                    res.data.data.find(element => element.labfancydiamond === 1) !== undefined 
                    ? [res.data.data.find(element => element.labfancydiamond === 1)] 
                    :[]
                  )
                
              }
            })
            .catch((res) => {
                console.warn(res);
            });
          }
        })
        GetCookies("language").then(response => {  
          if(response){
              setLanguage(response)
          }
      })
      axios({
        method: 'GET',
        url:"https://api.ipify.org",
    })
      .then(function (res) {
          localStorage.setItem("ipData",res.data)
      })
      .catch((res) => {
        
      });
      }
     
    }, [isAuthorized]);

    // console.log("naturalData",naturalData)
    // console.log("naturalfancyData",naturalFancyData)
    // console.log("labData",labData)
    // console.log("labFancyData",labFancyData)
    // console.log("-----------------------------------------------------")
    // console.log(languages[language],"LANGUAGE")
    return (
        <>
            <style>
                {`

                body{
                    background-image:url(${LOGO_BASE_URL}/${themeData.background_image})!important;
                    background-color:${themeData.background_color||"#EEF0F8"};
                    transition: background-image 1s;
                    background-attachment: fixed!important;
                    background-size:cover!important;
                }

                .header {
                  background-color: ${themeData.nav_bar_color || "#ffffff"}!important;
                  color: ${themeData.nav_bar_font || "#000000"}!important;
                }
                .header .user-logo-alt-name {
                  color:  ${themeData.nav_bar_font|| "#000000"}!important;
                }
                    
                ::selection {
                    color: white;
                    background: ${themeData.NaturalPrimaryColor};
                }
                body::-webkit-scrollbar {
                  height: 8px;
                  width:  8px;
                }
                /*Track */
                body::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 2px grey;
                }
                /*Handle */
                body::-webkit-scrollbar-thumb {
                    background: ${themeData.NaturalPrimaryColor};
                    border-radius: 10px;
                }
                body::-webkit-scrollbar-thumb:hover {
                    background: ${themeData.NaturalPrimaryColor}8f;
                    scrollbar-color: ${themeData.NaturalPrimaryColor}8f rgb(0 33 115 / 56%);
                }

                .table-responsive::-webkit-scrollbar {
                  height: 10px;
                  width:  10px;
                }
                /*Track */
                .table-responsive::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 2px grey;
                }
                /*Handle */
                .table-responsive::-webkit-scrollbar-thumb {
                    background: ${themeData.NaturalPrimaryColor};
                    border-radius: 10px;
                }
                .table-responsive::-webkit-scrollbar-thumb:hover {
                    background: ${themeData.NaturalPrimaryColor}8f;
                    scrollbar-color: ${themeData.NaturalPrimaryColor}8f rgb(0 33 115 / 56%);
                }
                .natTab .search_accordion .card .card-header {
                    background-color: ${themeData.NaturalPrimaryColor}!important;
                    color: ${themeData.NaturalFontColor};
                }
                .natTab .search_btn_check > input:checked ~ .selectBox {
                    background-color:${themeData.NaturalPrimaryColor};
                    color: ${themeData.NaturalFontColor};
                }
                .theme3 .natTab .search_btn_check > .selectBox{
                  background-color: ${themeData.NaturalPrimaryColor}15;
                  border: 1px solid #00217300;
                }
                .natTab .selectBox:hover ,.theme3 .natTab .selectBox:hover{
                    border: 1px solid ${themeData.NaturalPrimaryColor}!important;
                }
                .natTab .search_btn_check > .selectBox {
                    background-color: ${themeData.NaturalPrimaryColor}10;
                    border: 1px solid ${themeData.NaturalPrimaryColor}40;
                }
                .natTab .search_btn_check > input:checked ~ .selectBox span {
                  color: ${themeData.NaturalFontColor};
                }
                .natTab .text-primary{color: ${themeData.NaturalPrimaryColor}!important;}
                .natTab.text-primary{color: ${themeData.NaturalPrimaryColor}!important;}
                .natTab .btn.btn-primary {
                  color:${themeData.NaturalFontColor}!important;
                  background-color: ${themeData.NaturalPrimaryColor};
                  border-color: ${themeData.NaturalPrimaryColor};
                }

                .natTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .natTab .btn.btn-primary:focus:not(.btn-text),  .natTab .btn.btn-primary.focus:not(.btn-text) {
                  color:${themeData.NaturalFontColor}!important!important;
                  background-color: ${themeData.NaturalPrimaryColor}cc;
                  border-color: ${themeData.NaturalPrimaryColor}cc;
                }

                .natTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .natTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .natTab .show > .btn.btn-primary.dropdown-toggle, .natTab .show .btn.btn-primary.btn-dropdown {
                  color:${themeData.NaturalFontColor}!important;
                  background-color: ${themeData.NaturalPrimaryColor}cc;
                  border-color: ${themeData.NaturalPrimaryColor}cc;
                }
                .natTab .MuiSlider-colorSecondary {
                    color: ${themeData.NaturalPrimaryColor};
                }
                .natTab .MuiSlider-valueLabel span span {
                    color: ${themeData.NaturalFontColor};
                }
                .natTab .MuiTabs-indicator{ background-color: ${themeData.NaturalPrimaryColor};}
                .natTab .custom-seprator{
                  border-top: 1px solid  ${themeData.NaturalPrimaryColor};
                }
                .natTab .custom-result-box{
                    color: ${themeData.NaturalPrimaryColor}!important;
                    background-color: ${themeData.NaturalPrimaryColor}14 !important;
                    border-color: ${themeData.NaturalPrimaryColor} !important;
                }
                .natTab .MuiTab-textColorSecondary.Mui-selected{
                  color: ${themeData.NaturalPrimaryColor};
                }
                .natTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${themeData.NaturalFontColor};
                  fill: ${themeData.NaturalFontColor};
                }

                .search_btn_check > input:checked ~ .selectBox g .round-svg[fill], .search_btn_check > input:checked ~ .selectBox g .cls-1round[fill], .search_btn_check > input:checked ~ .selectBox g .oval-shape[fill], .search_btn_check > input:checked ~ .selectBox g .cushion-shape1[fill]{
                  fill: none!important;
                }
                
                .natTab .search_btn_check > input:checked ~ .selectBox g rect[fill] {
                  stroke: ${themeData.NaturalFontColor}00;
                }

                .labTab .search_accordion .card .card-header {
                  background-color: ${themeData.LabPrimaryColor}!important;
                  color: ${themeData.LabFontColor};
                }
                .labTab .search_btn_check > input:checked ~ .selectBox {
                    background-color:${themeData.LabPrimaryColor};
                    color: ${themeData.LabFontColor};
                }
                theme3 .labTab .search_btn_check > input:checked ~ .selectBox {
                  background-color:${themeData.LabPrimaryColor}!important;
                  border-radius:8px;
                }
                .labTab .selectBox:hover ,.theme3 .labTab .selectBox:hover{
                    border: 1px solid ${themeData.LabPrimaryColor}!important;
                }
                .labTab .search_btn_check > .selectBox {
                    background-color: ${themeData.LabPrimaryColor}10;
                    border: 1px solid ${themeData.LabPrimaryColor}40;
                }
                .theme3 .labTab .search_btn_check > .selectBox{
                  background-color: ${themeData.LabPrimaryColor}15;
                  border: 1px solid #00217300;
              }
                .labTab .search_btn_check > input:checked ~ .selectBox span {
                  color: ${themeData.LabFontColor};
                }

                .labTab .search_btn_check > input:checked ~ .selectBox g [fill] {
                  stroke: ${themeData.LabFontColor};
                  fill: ${themeData.NaturalFontColor};
                }

                .labTab .search_btn_check > input:checked ~ .selectBox g rect[fill] {
                  stroke: ${themeData.LabFontColor}00;
                }
                .labTab .text-primary{color: ${themeData.LabPrimaryColor}!important;}
                .labTab.text-primary{color: ${themeData.LabPrimaryColor}!important;}
                .labTab .btn.btn-primary {
                  color:${themeData.LabFontColor}!important;
                  background-color: ${themeData.LabPrimaryColor};
                  border-color: ${themeData.LabPrimaryColor};
                }

                .labTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .labTab .btn.btn-primary:focus:not(.btn-text),  .labTab .btn.btn-primary.focus:not(.btn-text) {
                  color:${themeData.LabFontColor}!important;
                  background-color: ${themeData.LabPrimaryColor}cc;
                  border-color: ${themeData.LabPrimaryColor}cc;
                }

                .labTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .labTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .labTab .show > .btn.btn-primary.dropdown-toggle, .labTab .show .btn.btn-primary.btn-dropdown {
                  color:${themeData.LabFontColor}!important;
                  background-color: ${themeData.LabPrimaryColor}cc;
                  border-color: ${themeData.LabPrimaryColor}cc;
                }
                .labTab .MuiSlider-colorSecondary {
                    color: ${themeData.LabPrimaryColor};
                }
                .labTab .MuiTabs-indicator{ background-color: ${themeData.LabPrimaryColor};}
                .labTab .custom-seprator{
                  border-top: 1px solid  ${themeData.LabPrimaryColor};
                }
                .labTab .custom-result-box{
                  color: ${themeData.LabPrimaryColor}!important;
                  background-color: ${themeData.LabPrimaryColor}14 !important;
                  border-color: ${themeData.LabPrimaryColor} !important;
                }
                .labTab .MuiTab-textColorSecondary.Mui-selected{
                  color: ${themeData.LabPrimaryColor};
                }
                .labTab .MuiSlider-valueLabel span span {
                  color: ${themeData.NaturalFontColor};
                }
            `}
            </style>
            <Switch>
                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage />
                    </Route>
                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={Logout} />

                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/" />
                ) : (
                    <Layout language={languages[language]} themeData={themeData}>
                        <BasePage 
                          themeData={themeData} 
                          theme={theme} 
                          user={user} 
                          ruleData={ruleData} 
                          naturalData={naturalData} 
                          naturalFancyData={naturalFancyData} 
                          labData={labData} 
                          labFancyData={labFancyData} 
                          language={languages[language]}/>
                    </Layout>
                )}
            </Switch>
        </>
    );
}
