import React, { useEffect , useState } from "react";
import { Slider, TextField,makeStyles } from "@material-ui/core";
import { validateInput } from "../../../../utilities/inputValidation";
// import { Field } from "formik";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";

const useStyles = makeStyles({
    TextField: {
      width: 80,
      margin:0
    },
  });

export const RatioFancy = ({formik,naturalFancyData,language,location}) => {
    const classes = useStyles();
    const [minRatio, setMinRatio] = useState(location && location.Formik && typeof (location.Formik.minRatio) === "number"?location.Formik.minRatio:naturalFancyData.length !== 0 && naturalFancyData[0].ratiomin ? naturalFancyData[0].ratiomin : 0);
    const [maxRatio, setMaxRatio] = useState(location && location.Formik && typeof (location.Formik.maxRatio) === "number"?location.Formik.maxRatio:naturalFancyData.length !== 0 && naturalFancyData[0].ratiomax ? naturalFancyData[0].ratiomax : 10);
    const [ratioRangeValue, setRatioRangeValue] = useState([location && location.Formik && typeof (location.Formik.minRatio) === "number"?location.Formik.minRatio:naturalFancyData.length !== 0 && naturalFancyData[0].ratiomin ? naturalFancyData[0].ratiomin : 0, location && location.Formik && typeof (location.Formik.maxRatio) === "number"?location.Formik.maxRatio:naturalFancyData.length !== 0 && naturalFancyData[0].ratiomax ? naturalFancyData[0].ratiomax : 10]);
    const minRatioRange = naturalFancyData.length !== 0 && naturalFancyData[0].ratiomin ? naturalFancyData[0].ratiomin : 0;
    const maxRatioRange = naturalFancyData.length !== 0 && naturalFancyData[0].ratiomax ? naturalFancyData[0].ratiomax : 10;

    useEffect(() => {
        formik.setFieldValue("minRatio", minRatio);
        formik.setFieldValue("maxRatio", maxRatio);
    }, []);

    const handleRatioRangeChange = (event, newValue) => {
        setMinRatio(newValue[0]);
        setMaxRatio(newValue[1]);
        setRatioRangeValue(newValue);
        // formik.setFieldValue("minRatio", newValue[0]);
        // formik.setFieldValue("maxRatio", newValue[1]);
    };

    const handleRatioRangeChangeCommitted = (event, newValue) => {
        formik.setFieldValue("minRatio", newValue[0]);
        formik.setFieldValue("maxRatio", newValue[1]);
    };

    return (
        <>
            <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_RATIO}</div>
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-md-12">
                            <Slider
                                getAriaLabel={() => "Ratio Range"}
                                value={ratioRangeValue}
                                onChangeCommitted={handleRatioRangeChangeCommitted}
                                onChange={handleRatioRangeChange}
                                step={0.01}
                                valueLabelDisplay="auto"
                                // getAriaValueText={ratioRangeValue}
                                min={minRatioRange}
                                max={maxRatioRange}
                                color="secondary"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                name="minRatio"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={ratioRangeValue[0]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    step:"0.05",
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    if(validateInput(e.target.value)) {
                                        setMinRatio(Number(e.target.value));
                                        setRatioRangeValue([
                                            Number(e.target.value),
                                            ratioRangeValue[1],
                                        ]);
                                        formik.setFieldValue("minRatio", Number(e.target.value));
                                    }                                    
                                }}
                            />
                                
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                variant="outlined"
                                name="maxRatio"
                                InputLabelProps={{ shrink: true }}
                                value={ ratioRangeValue[1]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    step:"any",
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    if(validateInput(e.target.value)) {
                                        setMaxRatio(Number(e.target.value));
                                        setRatioRangeValue([
                                            ratioRangeValue[0],
                                            Number(e.target.value),
                                        ]);
                                        formik.setFieldValue("maxRatio", Number(e.target.value));
                                    }                                    
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
