import React, { useEffect , useState } from "react";
import { Field, useFormikContext } from "formik";
import {toAbsoluteUrl} from "../../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";

export const ShapeLabFancy = ({labFancyData,language}) => {
    const [shape] = useState( labFancyData.length !== 0 && labFancyData[0].lab_shape !== null ? labFancyData[0].lab_shape : [] );
        
    const newShapeData =shape.length!==0?shape.split(',').map((value,index) => {
        return {
            id: index,
            value,
            multilingualshape:language[`${value.toUpperCase()}`]
        }
    }):null

    
    const [shapeLimit, setShapeLimit] = useState(12);
    const [selectedShapes, setSelectedShapes] = useState([]);
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
        setFieldValue("Shape_all", selectedShapes.length === newShapeData.length);
    }, [selectedShapes, setFieldValue]);

    const showMoreShape = () => setShapeLimit(newShapeData.length);
    const showLessShape = () => setShapeLimit(12);

    const handleSelectAll = () => {
        const newSelectedAll = !values.Shape_all;
        setFieldValue("Shape_all", newSelectedAll);
        setSelectedShapes(newSelectedAll ? newShapeData.map(item => item.value) : []);
        setFieldValue("Shape", newSelectedAll ? newShapeData.map(item => item.value) : []);
    };

    const handleSelectShape = (value) => {
        const newSelectedShapes = selectedShapes.includes(value)
            ? selectedShapes.filter(item => item !== value)
            : [...selectedShapes, value];
        setSelectedShapes(newSelectedShapes);
        setFieldValue("Shape", newSelectedShapes);
    };
    return (
        <>
            <div className="row">
                <div className="col-12 font-size-lg font-weight-bold mb-3 text-center">{language.CCMODE_THEME1_SEARCHDIAMOND_SHAPE}</div>
                <div className="col-12 d-flex flex-wrap justify-content-center">
                    <label className="search_btn_check">
                        <input
                            type="checkbox"
                            name="Shape_all"
                            id="shape_all"
                            onChange={handleSelectAll}
                            checked={values.Shape_all}
                        />
                        <div className="shapeBox selectBox d-flex align-items-center justify-content-center">
                            <span className="text-uppercase font-size-h1">ALL</span>
                        </div>
                    </label>
                    {newShapeData ? newShapeData.slice(0, shapeLimit).map((item) => (
                        <OverlayTrigger placement="top" key={item.id} overlay={<Tooltip id="tooltip" >{item.multilingualshape}</Tooltip>} >
                            <label className="search_btn_check">
                                <Field 
                                    type="checkbox" 
                                    name="Shape" 
                                    id={`${item.value}_shape`} 
                                    value={item.value}
                                    checked={selectedShapes.includes(item.value)}
                                    onChange={() => handleSelectShape(item.value)}
                                />
                                {/* <span></span> */}
                                <div className="shapeBox selectBox">
                                    <SVG src={toAbsoluteUrl(`/media/shape/svg_shape/${item.value.toLowerCase()}.svg`)} />
                                    <br/>
                                    <span className="text-uppercase text-truncate">{item.multilingualshape}</span>
                                </div>
                            </label>
                        </OverlayTrigger>
                    )):null}
                     {newShapeData.length > 12 &&
                        <span className="search_btn_check" onClick={shapeLimit === newShapeData.length ? showLessShape : showMoreShape}>
                            <div className="shapeBox selectBox">
                                {shapeLimit === newShapeData.length ? <CiCircleMinus /> : <CiCirclePlus />}<br />
                                <span className="text-uppercase text-truncate">{shapeLimit === newShapeData.length ? "Less" : "More"}</span>
                            </div>
                        </span>
                    }
                </div>
            </div>
        </>
    );
};