/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo,useEffect,useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Link,useHistory } from "react-router-dom";
import { FaVideo } from "react-icons/fa";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
// import { green } from '@mui/material/colors'
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import axios from "axios";
import {IUD_SHORTLIST} from "./../../../../../env_config"
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import VideoIframe from "./VideoIframe";
// import DiamondContext from "../../searchDiamond/SearchDiamondContext";
import { StoreDiamondDB,GetDiamondDB } from "../../../../_helpers/IndexedDB";
import { useSelector } from "react-redux";
export const GridCardContent = (props) => {
    const [diamondsearch,setDiamondSearch] = useState([])
    const [newprops,setProps] = useState(props)
    useEffect(() => {
        GetDiamondDB().then(response => {
            setDiamondSearch(response)
        })
    },[])
    // const diamondsearch = useContext(DiamondContext)
    const [update,setUpdate] = React.useState(props.Shortlisted);
    const history = useHistory();

    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const id = useMemo(()=>localStorage.getItem("C_id"),[])
    const userCurrency = useMemo(()=>localStorage.getItem("userCurrency"),[])
    const usercur = JSON.parse(userCurrency)
    function Currency(){
        if (user.Currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (user.Currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (user.Currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (user.Currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (user.Currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (user.Currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (user.Currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }
    }
    // console.log(props)
    // -------------------------------------------------

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------
    const [showWithoutModel, setShowWithoutModel] = React.useState(false);
    const [showVideo, setShowVideo] = React.useState(false);

    function handleClose(event, reason) {
        setOpen(false);
        setShowVideo(false)
        setSuccessOpen(false);
        setShowWithoutModel(false);
    }
    function withoutSession() {
        setOpen(true);
    }
    function openModal() {
        setShowWithoutModel(true);
        // setOpen(true);
    }
    function openVideoModal() {
        setShowVideo(true)
        // setOpen(true);
    }
    function handelDetails(props){
        history.push({  pathname: '/details', state:newprops})
        // const finalData = `{ "JsonData":  [{"ProductId":${props.ProductId}}] , "CustomerId": ${user.CustomerId}, "ConsumerId":${id=== "" ? "-1" : id}, "WLDiamondType": "${props.diamond_type}" }`;
        // axios({
        //     method: "POST",
        //     url: DETAILPAGE_LOG,
        //     headers: {
        //         "Access-Control-Allow-Origin": "*",
        //         "Content-Type": "application/json",
        //     },
        //     data: finalData,
        // })
        // .then(function(res) {
        //     // setSuccessOpen(true);
        //     // setShowWithoutModel(false);
        // })
        // .catch(function(res) {
        //     // console.log("error",values);
        //     console.log(res);
        // });
        window.scrollTo(0, 0)
    }
    function handleShortlist(props) {
        // console.log(props,"propsprops")
        const newdiamondata = diamondsearch.map(value => {
            if(value.ProductId === props.ProductId){
                value.Shortlisted = 1
            }
            return value
        })
        setProps({
            ...props,
            Shortlisted:1
        })
        // console.log(newdiamondata,"newdiamondata")
        StoreDiamondDB(newdiamondata)
        const values = JSON.stringify({
            Tag: "I",
            Id: 0,
            CustomerId: user.CustomerId,
            ConsumerId: id,
            ProductId: props.ProductId,
            CertiNo: props.CERTIFICATE_NO,
            WLDiamondType: `${props.diamond_type}`,
            Currency: user.Currency,
            CurrencyConversionRate: usercur[0].ConversionRate,
            MarkUpNatural: user.MarkUpNatural,
            MarkUpNaturalFancy: user.MarkUpNaturalFancy,
            MarkUpLab: user.MarkUpLab,
            MarkUpLabFancy: user.MarkUpLabFancy,
            OurRate: props.OurRate,
            OurPrice: props.OurPrice,
            MarkUpRate: props.MarkUpRate,
            MarkUpPrice: props.MarkUpPrice,
            CreatedAt: "0000-00-00 00:00:00",
        });
        axios({
            method: "POST",
            url: IUD_SHORTLIST,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            data: values,
        })
            .then(function(res) {
                // console.log(res.data);
                setUpdate(1)
                setSuccessOpen(true);
                setShowWithoutModel(false);
            })
            .catch(function(res) {
                console.log("error",values);
                // console.log(res);
            });
    }
    // const  certificate_link =''
    function certificateLink(){
        if (props.LAB === "IGI"){
            const  certificate_link = `https://www.igi.org/viewpdf.php?r=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.LAB === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.CERTIFICATE_NO}`
            return certificate_link
        } else if (props.CERTIFICATE_LINK){
            const certificate_link = props.CERTIFICATE_LINK
            return certificate_link
        }
    }

    const { hidePrice, hideCertificateNumber } = useSelector(
        (state) => state.CcModeSettingReducer
      );

    function numberWithCommas(x) {
        if(x){
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else{
            return "0.00"
        }
    }

    const GetPricePerCarat = (data) => {
        let selectedconversionrate = 1 
        if (localStorage.getItem("user_currency") === "INR") {
            selectedconversionrate = data.currencies.cur_inr
        } 
        if (localStorage.getItem("user_currency") === "HKD") {
            selectedconversionrate = data.currencies.cur_hkd
        } 
        if (localStorage.getItem("user_currency") === "AUD") {
            selectedconversionrate = data.currencies.cur_aud
        } 
        if (localStorage.getItem("user_currency") === "EUR") {
            selectedconversionrate = data.currencies.cur_eur
        } 
        if (localStorage.getItem("user_currency") === "CAD") {
            selectedconversionrate = data.currencies.cur_cad
        } 
        if (localStorage.getItem("user_currency") === "CNY") {
            selectedconversionrate = data.currencies.cur_cny
        } 
        if (localStorage.getItem("user_currency") === "GBP") {
            selectedconversionrate = data.currencies.cur_gbp
        } 
        if (localStorage.getItem("user_currency") === "NZD") {
            selectedconversionrate = data.currencies.cur_nzd
        } 
        if (localStorage.getItem("user_currency") === "JPY") {
            selectedconversionrate = data.currencies.cur_jpy
        } 
        if (localStorage.getItem("user_currency") === "CHF") {
            selectedconversionrate = data.currencies.cur_chf
        } 
        if (localStorage.getItem("user_currency") === "SGD") {
            selectedconversionrate = data.currencies.cur_sgd
        } 
        if (localStorage.getItem("user_currency") === "THB") {
            selectedconversionrate = data.currencies.cur_thb
        } 
        if (localStorage.getItem("user_currency") === "MYR") {
            selectedconversionrate = data.currencies.cur_myr
        } 
        if (localStorage.getItem("user_currency") === "BND") {
            selectedconversionrate = data.currencies.cur_bnd
        } 
        if (localStorage.getItem("user_currency") === "DKK") {
            selectedconversionrate = data.currencies.cur_dkk
        } 
        if (localStorage.getItem("user_currency") === "SEK") {
            selectedconversionrate = data.currencies.cur_sek
        }
        let markupusdprice = (data.MarkUpPrice/data.CurrencyConversionRate) * selectedconversionrate
        return Math.round(markupusdprice/data.C_Weight)
    }

    const GetPrice = (data) => {
        let selectedconversionrate = 1 
        if (localStorage.getItem("user_currency") === "INR") {
            selectedconversionrate = data.currencies.cur_inr
        } 
        if (localStorage.getItem("user_currency") === "HKD") {
            selectedconversionrate = data.currencies.cur_hkd
        } 
        if (localStorage.getItem("user_currency") === "AUD") {
            selectedconversionrate = data.currencies.cur_aud
        } 
        if (localStorage.getItem("user_currency") === "EUR") {
            selectedconversionrate = data.currencies.cur_eur
        } 
        if (localStorage.getItem("user_currency") === "CAD") {
            selectedconversionrate = data.currencies.cur_cad
        } 
        if (localStorage.getItem("user_currency") === "CNY") {
            selectedconversionrate = data.currencies.cur_cny
        } 
        if (localStorage.getItem("user_currency") === "GBP") {
            selectedconversionrate = data.currencies.cur_gbp
        } 
        if (localStorage.getItem("user_currency") === "NZD") {
            selectedconversionrate = data.currencies.cur_nzd
        } 
        if (localStorage.getItem("user_currency") === "JPY") {
            selectedconversionrate = data.currencies.cur_jpy
        } 
        if (localStorage.getItem("user_currency") === "CHF") {
            selectedconversionrate = data.currencies.cur_chf
        } 
        if (localStorage.getItem("user_currency") === "SGD") {
            selectedconversionrate = data.currencies.cur_sgd
        } 
        if (localStorage.getItem("user_currency") === "THB") {
            selectedconversionrate = data.currencies.cur_thb
        } 
        if (localStorage.getItem("user_currency") === "MYR") {
            selectedconversionrate = data.currencies.cur_myr
        } 
        if (localStorage.getItem("user_currency") === "BND") {
            selectedconversionrate = data.currencies.cur_bnd
        } 
        if (localStorage.getItem("user_currency") === "DKK") {
            selectedconversionrate = data.currencies.cur_dkk
        } 
        if (localStorage.getItem("user_currency") === "SEK") {
            selectedconversionrate = data.currencies.cur_sek
        }
        let markupusdprice = (data.MarkUpPrice/data.CurrencyConversionRate) * selectedconversionrate
        return Math.round(markupusdprice)
    }


    if(user === undefined ){
        return
    }

    return (
        <>
            <div
                className={`col-sm-6 col-md-4 col-lg-3 mb-5  ${
                    props.diamond_type === "N" ? "natTab" : "labTab"
                }`}
                key={props.ProductId}
                id={props.ProductId}
            >
                <div className="row m-0 card gridData">
                    <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200" style={{height:"220px"}}>
                        {props.AWS_Image ? (
                            // <img
                            // className="img-fluid"
                            // alt={props.C_Shape}
                            // src={props.AWS_Image}
                            //     // src={toAbsoluteUrl(
                            //     //     `/media/shape/${props.C_Shape.toLowerCase()}-no.png`
                            //     // )}
                                
                            // />
                            <LazyLoadImage
                                className="img-fluid cursor-pointer"
                                onClick={() => handelDetails(props)}
                                src={ props.AWS_Image}
                                alt={props.C_Shape}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${props.C_Shape.toLowerCase()}-no.png`
                                        )
                                    }}
                                effect="blur" 
                            />
                        ) : (
                            <img
                                className="img-fluid cursor-pointer"
                                onClick={() => handelDetails(props)}
                                alt="shape"
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                                src={toAbsoluteUrl(
                                    `/media/shape/${props.C_Shape.toLowerCase()}-no.png`
                                )}
                            />
                            
                        )}
                    </div>
                    <div className="col-12 my-1 d-flex justify-content-between align-items-center">
                        <a
                            id={props.CERTIFICATE_NO}
                            href={certificateLink()}
                            target="_blank"
                            rel="noreferrer"
                            className="font-size-sm text-primary"
                        >
                            {props.LAB}
                        </a>
                       
                        <span className="font-size-sm text-primary">
                            {props.VIDEO ? (
                                <>
                                    <FaVideo className="font-size-sm text-primary cursor-pointer"  onClick={() => openVideoModal(props)} />
                                    <VideoIframe
                                        showVideo={showVideo}
                                        handleClose={handleClose}
                                        src={props.diamond_type === "N" ? `https://pro360video.com/video.php?refno=${props.CERTIFICATE_NO}` : `https://pro360video.com/labgrown.php?refno=${props.CERTIFICATE_NO}` }
                                    />
                                </>
                            ) : (
                                <span id={props.VIDEO} > </span>
                            )}
                        </span>
                    </div>
                    <div className="col-12 text-truncate">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {props.C_Weight && props.C_Weight.toFixed(2)} {props.C_Shape}{" "}
                                    {(props.FANCY_COLOR) ? (<>{props.FANCY_INTENSITY} {props.FANCY_OVERTONE} {props.FANCY_COLOR}</>): props.COLOR}{" "}
                                    {props.C_Clarity} {props.C_Cut}{" "}
                                    {props.C_Polish} {props.C_Symmetry} {props.C_Fluorescence} <br />
                                    M: {props.C_Length && props.C_Length.toFixed(2)} x {props.C_Width && props.C_Width.toFixed(2)} x {props.C_Depth && props.C_Depth.toFixed(2)} T:{props.C_TableP && props.C_TableP.toFixed(2)}%  D:{props.C_DefthP && props.C_DefthP.toFixed(2)}%  R:1.45
                                </Tooltip>
                            }
                        >
                            <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                                <span className="pr-2">
                                    {props.C_Weight.toFixed(2)}
                                </span>
                                <span className="pr-2">{props.C_Shape}</span>
                                { (props.FANCY_COLOR) ?
                                    (
                                        <>
                                             <span className="pr-2">{props.FANCY_COLOR}</span>
                                             <span className="pr-2">{props.FANCY_OVERTONE}</span>
                                             <span className="pr-2">{props.FANCY_COLOR}</span>
                                        </>
                                    ) : (
                                        <>
                                             <span className="pr-2">{props.COLOR}</span>
                                        </>
                                    )
                                }
                                <span className="pr-2">{props.C_Clarity}</span>
                                <span className="pr-2">{props.C_Cut}</span>
                                <span className="pr-2">{props.C_Polish}</span>
                                <span className="pr-2">{props.C_Symmetry}</span>
                                <span className="pr-2">{props.C_Fluorescence}</span>
                            </span>
                            
                        </OverlayTrigger>
                        <hr className="w-75 m-1 px-5 pt-2 pb-0" />
                    </div>

                    <div className="col-12 text-center">
                        <div className="font-size-h1 font-weight-bolder text-primary">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="currency_t">
                                    {Currency()}/CT {numberWithCommas(props.MarkUpRate)}
                                </Tooltip>
                            }
                        >
                           <span> {Currency()}{numberWithCommas(props.MarkUpPrice)}</span>
                        </ OverlayTrigger>
                        </div>
                        <hr className="w-75 m-1 px-5 py-2" />
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-between mb-5">
                        <button className="btn btn-secondary font-weight-bold" onClick={() => handelDetails(props)}>
                            Details
                        </button>
                        {id === "-1" || id === "-2" || id === "" ? (
                            <>
                                <button
                                    onClick={() => withoutSession()}
                                    className={`btn font-weight-bold ${update === 1 ? "btn-info" : "btn-danger"}`}
                                >
                                    {update === 1 ? "In Cart" : "Add to Cart"}
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    key={props.ProductId}
                                    onClick={() => openModal(props)}
                                    className={`btn font-weight-bold ${update === 1 ? "btn-info" : "btn-primary"}`}
                                >
                                    {update === 1 ? "In Cart" : "Add to Cart"}
                                </button>
                                <Modal
                                    show={showWithoutModel}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>{update === 1 ? "Warning !": " Confirmation"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p className="font-weight-bold">
                                            {update === 1 ? "": " Are you sure want to Add to Cart ?"}
                                            { (update === 1) ?
                                                (
                                                    <>
                                                        This stone is already in cart
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <span className="text-dark font-weight-bold text-capitalize font-size-h5">
                                                            <span className="pr-2">
                                                                {props.C_Weight.toFixed(2)}
                                                            </span>
                                                            <span className="pr-2">{props.C_Shape}</span>
                                                            <span className="pr-2">{props.C_Color}</span>
                                                            <span className="pr-2">{props.C_Clarity}</span>
                                                            <span className="pr-2">{props.C_Cut}</span>
                                                            <span className="pr-2">{props.C_Polish}</span>
                                                            <span className="pr-2">{props.C_Symmetry}</span>
                                                            <span className="pr-2">{props.C_Fluorescence}</span>
                                                        </span> */}
                                                        <div key={props.ProductId} className="d-flex w-100 mb-4 pb-4 px-4 border-bottom justify-content-between">
                                                            <div className="d-flex">
                                                                <div className="text-center p-0 inactive-block">
                                                                    {props.AWS_Image &&  props.AWS_Image !=="null" ? (
                                                                        <img
                                                                            className="img-fluid mr-2"
                                                                            alt={props.C_Shape}
                                                                            src={props.AWS_Image}
                                                                            onError={e => {
                                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                                    `/media/shape/${props.C_Shape && props.C_Shape.toLowerCase()}-no.png`
                                                                                )
                                                                            }}
                                                                            width="115px"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            className="img-fluid mr-2"
                                                                            alt={props.C_Shape}
                                                                            src={toAbsoluteUrl(
                                                                                `/media/shape/${props.C_Shape && props.C_Shape.toLowerCase()}-no.png`
                                                                            )}
                                                                            onError={e => {
                                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                                    `/media/shape/noimage.jpg`
                                                                                )
                                                                            }}
                                                                            width="115px"
                                                                            style={{ backgroundColor: "#f4f4f4" }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="pl-2 p-0 text-left">
                                                                    <div className={`mb-1 ${props.WLDiamondType === "N" ? "natTab" : "labTab"} inactive-block`}>
                                                                        <span className={`${ props.WLDiamondType === "N" ? props.C_Color === "fancy" ? "F" : "W" : props.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                                                            {props.WLDiamondType === "N" || props.WLDiamondType === "W" ? props.C_Color === "fancy"  ? "NF" : "N" : props.C_Color === "fancy" ? "LF" : "L"}
                                                                        </span>
                                                                        <span className={`text-primary font-weight-bold small ml-1`}>
                                                                            {props.WLDiamondType === "N" ? "N" : "L"}-{props.ProductId}
                                                                        </span>
                                                                        {
                                                                            hideCertificateNumber ?
                                                                            <span className="text-primary font-weight-bold mx-2 small">{props.Lab}</span> :
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                className="text-primary font-weight-bold mx-2 small"
                                                                                href={
                                                                                    props.Lab === "IGI"
                                                                                        ? `https://www.igi.org/viewpdf.php?r=${props.Certi_No}`
                                                                                        : props.Lab === "GIA"
                                                                                        ? `https://www.gia.edu/report-check?reportno=${props.Certi_No}`
                                                                                        : props.Lab === "HRD"
                                                                                        ? `http://ws2.hrdantwerp.com/HRprops.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_No}`
                                                                                        : props.Lab === "GCAL"
                                                                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.Certi_No}`
                                                                                        : props.Certi_link
                                                                                }
                                                                            >

                                                                                {props.Lab}-{props.Certi_No}
                                                                            </a>
                                                                        }                                                            
                                                                    </div>
                                                                    <span className="text-dark font-size-lg mb-2 font-weight-bold d-block inactive-block">
                                                                        {props.C_Shape}{" "}
                                                                        {props.C_Weight}{" "}
                                                                        {props.C_Color === "fancy" ? (
                                                                            <>
                                                                                {props.f_color}{" "}
                                                                            </>
                                                                        ) : (
                                                                            <>{props.C_Color}{" "}</>
                                                                        )}
                                                                        {props.C_Clarity}{" "}{props.C_Cut}{" "}{props.C_Polish}{" "}{props.C_Symmetry}{" "}
                                                                        {props.C_Fluorescence}
                                                                    </span>
                                                                    <div className="font-weight-bold inactive-block">Measurement: {props.C_Length}x{props.C_Width}x{props.C_Depth}</div>
                                                                    <div className="mb-2 inactive-block"><span className="font-weight-bold">T :</span> {props.C_TableP ? parseFloat(props.C_TableP).toFixed(2) : "00.00"}%  |  <span className="font-weight-bold">D :</span>  {props.C_DefthP ? parseFloat(props.C_DefthP).toFixed(2) : "00.00"}%</div>
                                                                    {props.purchased ? (
                                                                        <div className="text-info font-weight-bolder">
                                                                            Purchased
                                                                        </div>
                                                                    ) : props.Status === "0" ? (
                                                                        <div className="text-success font-weight-bolder">
                                                                            In Stock
                                                                        </div>
                                                                    ) : (
                                                                        <div className="text-danger outofstock-label font-weight-bolder">
                                                                            Out Of Stock
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="inactive-block">
                                                                {
                                                                    !hidePrice && (                                                            
                                                                        <>
                                                                            <div className="text-right">
                                                                                <p className="text-muted font-weight-bold small mb-0 text-uppercase">{localStorage.getItem("user_currency")}/CT</p>
                                                                                <p className="text-dark font-size-lg font-weight-bold">
                                                                                    <span>{Currency(localStorage.getItem("user_currency"))}</span>
                                                                                    {numberWithCommas(GetPricePerCarat(props))}
                                                                                </p>
                                                                            </div>
                                                                            
                                                                            <div className="text-right">
                                                                                <p className="text-muted font-weight-bold small mb-0 text-uppercase">Total</p>
                                                                                <p className="text-dark font-size-lg font-weight-bold">
                                                                                    <span>{Currency(localStorage.getItem("user_currency"))}</span>
                                                                                    {numberWithCommas(GetPrice(props))}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }                                                                                                        
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                        >
                                           {update === 1 ? "Ok": "No"}
                                        </Button>
                                        { (update === 1) ?
                                            (
                                                <> </>
                                            ) : (
                                                <>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() =>
                                                            handleShortlist(props)
                                                        }
                                                    >
                                                        Yes
                                                    </Button>
                                                </>
                                            )
                                        }
                                    </Modal.Footer>
                                </Modal>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message="You must start a session before adding stones to your cart"
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message="Your stone is added to cart"
                />
            </Snackbar>
        </>
    );
};
