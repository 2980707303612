import React, { useState } from 'react';
import {  Modal, Button } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Formik, Field } from "formik";
import {Autocomplete} from '@material-ui/lab';
import {TextField} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {FETCH_COUNSUMER_LIST} from "../../../env_config"
import {IUD_CONSUMER_DETAILS} from "../../../env_config"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { NODE_API_URL } from '../../../env_config';
import { IoCloseSharp } from 'react-icons/io5';
const NewSesModelSchema = Yup.object().shape({
    ListName: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
});


export const SelectSessionModal = (props) => {
    
  const history = useHistory();
  const [listerror,setListError] = useState(null)
  const [emailerror,setEmailError] = useState(null)
//   const [showNewModel, setshowNewModel] = useState(false);
  const [loading, setLoading] = useState(false);
//   const [showPrevModel, setshowPrevModel] = useState(false);
  const [user, setUser] = React.useState([]);

  React.useEffect(() => {
    const userData = localStorage.getItem("userData");
    setUser(JSON.parse(userData))
  },[])
  React.useEffect(() => {
    if(user && user.CustomerId){
        axios({
            method: 'GET',
            url:`${FETCH_COUNSUMER_LIST}/${user.CustomerId}` ,
            headers: { 
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
                },
            })
            .then(function (res) {
                // console.log(res.data)
                // console.log(res)
                setItems(res.data);
            })
            .catch(function (res) {
                console.log(res)
                // console.log(user.CustomerId)
            });
    }
  },[user])

  const [id, setId] = useState([]);

  React.useEffect(() => window.localStorage.setItem("C_id", id), [id]);

  const [items, setItems] = useState([]);
  const [cid, setCid] = useState([]);

  const [inputValue, setInputValue] = React.useState('');


    const handleSubmitPrev = () =>{
        const finalData = {
            ConsumerId: cid,
            CustomerId: user.CustomerId,
            Action:"Session Started"
        }
        // console.log(finalData,"finalData")
        axios({
            method: "POST",
            url: `${NODE_API_URL}/ccmode/storeWhiteLabelConsumerDetails`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            window.localStorage.setItem("C_id", cid)
      setTimeout(() => {
          history.push("/searchdiamond");
        //   setshowPrevModel(false);
          setTimeout(() => {
            window.location.reload(true)
        }, 100);
      }, 1000);
        }).catch(err => {
            
        })
    }

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };
 
    // const newModalBtn = React.useRef(null);
    const prevModalBtn = React.useRef(null);
    const prevModalDiv = React.useRef(null);
    const newModalDiv = React.useRef(null);
    // const sessButtonDiv = React.useRef(null);
    const backModalBtn = React.useRef(null);

    // const newModalClick = (e) => {
    //     newModalDiv.current.classList.add("show");
    //     prevModalDiv.current.classList.remove("show");
    //     sessButtonDiv.current.classList.remove("show");
    // };

    const prevModalClick = (e) => {
        newModalDiv.current.classList.remove("show");
        prevModalDiv.current.classList.add("show");
        // sessButtonDiv.current.classList.remove("show");
    };
    const backClick = (e) => {
        newModalDiv.current.classList.add("show");
        prevModalDiv.current.classList.remove("show");
        // sessButtonDiv.current.classList.add("show");
    };

    if(user === undefined ){
      return
    }
    const ValidateConsumer = (event, setFieldValue) => {
        let finddata = items.find(value => value.ListName === event)
        // console.log(finddata,"finddata")
        if (finddata) {
            setListError("List Name Already Exists")
            setFieldValue("ListName", event)
        } else {
            setListError(null)
            setFieldValue("ListName", event)
        }
    }
    const ValidateEmail = (value,setFieldValue) => {
        if(!(/\S+@\S+\.\S+/.test(value))){
            setEmailError("Email is Invalid")
            setFieldValue("EMail",value)
        }
        else{
            setEmailError(null)
            setFieldValue("EMail",value)
        }
    }
     // Get device information
     function detectDevice() {
        // Check if the user agent contains the substring "Mobi"
        return /Mobi/.test(navigator.userAgent) ? "Mobile" : "Pc";
    }
    
    function detectOS() {
        const userAgent = navigator.userAgent;
        if (/Windows/.test(userAgent)) {
            return "Windows";
        } else if (/Macintosh/.test(userAgent)) {
            return "Mac OS";
        } else if (/Linux/.test(userAgent)) {
            return "Linux";
        } else if (/Android/.test(userAgent)) {
            return "Android";
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
            return "iOS";
        } else {
            return "Unknown";
        }
    }

    const deviceType = detectDevice();
    const os = detectOS();


    return (
      <>
        <div className="row">
            {/* <div className="col-12 text-center">
                <div className='session-modal-button' ref={sessButtonDiv}>
                    <button className="btn btn-primary btn-lg my-5"  onClick={prevModalClick} ref={prevModalBtn}>{props.language.CCMODE_THEME3_PREVIOUS_SESSION}</button> <br/>
                    <button className="btn btn-success btn-lg my-5"  onClick={newModalClick} ref={newModalBtn}> {props.language.CCMODE_THEME3_NEW_SESSION} </button>
                </div>
            </div> */}
            <div className="col-12 text-right">
                <IoCloseSharp className='cursor-pointer text-dark-50 font-size-h3' onClick={()=>{props.setOpen(false) ; props.setSessModalShow(false)}} />
            </div>
            <div className="col-12 px-md-10">
                <div className='my-1 session-modal' ref={prevModalDiv}>
                    <div className='mb-8 text-center'>
                        <h2 className='mb-0'>{props.language.CCMODE_THEME3_START_SESSION} </h2>
                        <span >Start Previous Session</span>
                    </div>
                    <Autocomplete
                        disablePortal
                        id="select-consumer"
                        options={items}
                        getOptionLabel={(items) => items.ListName}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label={props.language.CCMODE_THEME3_SELECT_CONSUMER} />}
                        onChange={(event, newValue) => {
                            setCid(newValue.ConsumerId);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                
                    />
                    <div className='mt-15 mb-10 text-right'>
                        <Button variant="secondary" onClick={backClick} ref={backModalBtn}> {props.language.CCMODE_THEME3_BACK} </Button>
                        <Button className="ml-2" onClick={handleSubmitPrev} variant="primary">{props.language.CCMODE_THEME3_START}</Button>
                    </div >
                </div>

                <div className='my-1 session-modal show' ref={newModalDiv}>
                    <div className='mb-5 text-center'>
                        <h2 className='mb-0'>{props.language.CCMODE_THEME3_START_SESSION} </h2>
                        <span >Start New Session</span>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            Tag: "I",
                            ConsumerId: 0,
                            CustomerId: `${user.CustomerId}`,
                            ip:window.localStorage.getItem("ipData"),
                            device:`${deviceType} - ${os}`
                        }}
                        validationSchema={NewSesModelSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            enableLoading();
                            setTimeout(() => {
                                // console.log(JSON.stringify(values, null, 2));
                                axios({
                                    method: "POST",
                                    url: IUD_CONSUMER_DETAILS,
                                    headers: {
                                        "Access-Control-Allow-Origin": "*",
                                        "Content-Type": "application/json",
                                    },
                                    data: JSON.stringify(values, null, 2),
                                })
                                    .then(function(res) {
                                        disableLoading();
                                        // console.log(res.data.Message,"res.data.Message")
                                        if(res.data.Message.includes("Insert of Consumer details failed.") || res.data.Message.includes("Insert/ Update Into WhiteLabelConsumerDetails Failed.")){
                                            alert("List Name Already Exist");
                                            // handleClose()
                                            props.setOpen(false)
                                            props.setSessModalShow(false)
                                        }else{
                                            // alert("Thank You for taking the time to fill out the form");
                                            setId(res.data.LastInsertedConsumerId);
                                            const finalData = {
                                                ConsumerId: res.data.LastInsertedConsumerId,
                                                CustomerId: user.CustomerId,
                                                Action:"Session Started"
                                            }
                                            // console.log(finalData,"finalData")
                                            axios({
                                                method: "POST",
                                                url: `${NODE_API_URL}/ccmode/storeWhiteLabelConsumerDetails`,
                                                headers: { "Content-Type": "application/json" },
                                                data: finalData,
                                            }).then(res => {
                                                // console.log(res,"RESPONSEEE")
                                            }).catch(err => {
                                                
                                            })
                                            if (res.data.status){
                                                history.push("/searchdiamond");
                                            }
                                            setSubmitting(false)
                                            setTimeout(() => {
                                                window.location.reload(true)
                                            }, 100);
                                            // handleClose()
                                        }
                                    })
                                    .catch(function(res) {
                                        setSubmitting(false)
                                        alert(res.data.Message);
                                        disableLoading();
                                    });
                            }, 1000);
                        }}
                    >
                        {({ handleSubmit, errors, touched, isSubmitting ,setFieldValue}) => (
                            <>
                                <div className="row px-md-10">
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            {props.language.CCMODE_THEME3_LISTNAME} :
                                        </label>
                                        <div className="form-group fv-plugins-icon-container">
                                            <Field
                                                className="form-control"
                                                type="text"
                                                name="ListName"
                                                id="ListName"
                                                placeholder="List Name"
                                                autoComplete="off"
                                                onChange={(event) => ValidateConsumer(event.target.value,setFieldValue)}
                                            />
                                            {errors.ListName? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">
                                                        {errors.ListName}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {listerror?<div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                        {listerror}
                                                    </div>
                                            </div>:null}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                        {props.language.CCMODE_THEME3_CONSUMERNAME} :
                                        </label>
                                        <div className="form-group">
                                            <Field
                                                className="form-control"
                                                type="text"
                                                name="ConsumerName"
                                                id="ConsumerName"
                                                placeholder="Consumer Name"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            {props.language.CCMODE_THEME3_EMAIL} :
                                        </label>
                                        <div className="form-group">
                                            <Field
                                                className="form-control"
                                                type="email"
                                                name="EMail"
                                                id="EMail"
                                                placeholder="email"
                                                autoComplete="off"
                                                onChange={(e) => ValidateEmail(e.target.value,setFieldValue)}
                                            />
                                            {emailerror?<div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                        {emailerror}
                                                    </div>
                                            </div>:null}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            {props.language.CCMODE_THEME3_CONTACT} :
                                        </label>
                                        <div className="form-group sess-phone">
                                            <PhoneInput
                                                // className="form-control"
                                                type="text"
                                                name="ContactNo"
                                                id="ContactNo"
                                                placeholder="contact number"
                                                autoComplete="off"
                                                onChange={(value) => setFieldValue("ContactNo",value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label className="font-weight-bold">
                                            {props.language.CCMODE_THEME3_NOTES} :
                                        </label>
                                        <div className="form-group">
                                            <Field
                                                component="textarea"
                                                className="form-control"
                                                type="text"
                                                name="Notes"
                                                id="Notes"
                                                placeholder="Add notes"
                                                autoComplete="off"
                                                maxLength="250"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 text-center">
                                    {/* <Button variant="secondary" onClick={backClick} ref={backModalBtn}>  {props.language.CCMODE_THEME3_BACK} </Button> */}
                                    <button className='btn btn-light-warning font-weight-bold mr-2' onClick={()=>{props.setOpen(false) ; props.setSessModalShow(false)}}>close</button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary ml-2"
                                        disabled={isSubmitting}
                                        onClick={handleSubmit}
                                    >
                                        {props.language.CCMODE_THEME3_START}
                                        {loading && (
                                            <span className="ml-3 spinner spinner-white"></span>
                                        )}
                                    </button>
                                    <div className="mt-5 cursor-pointer text-decoration-underline font-weight-bold text-primary text-hover-success"  onClick={prevModalClick} ref={prevModalBtn}>{props.language.CCMODE_THEME3_PREVIOUS_SESSION}</div>
                                </div >
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
      </>
    );
  }
  