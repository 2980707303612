import React, { useEffect , useState } from "react";
import { Slider, TextField,makeStyles } from "@material-ui/core";
import { validateInput } from "../../../../utilities/inputValidation";
// import { Field } from "formik";
// import {toAbsoluteUrl} from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";

const useStyles = makeStyles({
    TextField: {
      width: 80,
      margin:0
    },
  });

export const DepthLabFancy = ({formik,labFancyData,language,location}) => {
    const classes = useStyles();
    const [minDepth, setMinDepth] = useState(location && location.Formik && typeof (location.Formik.minDepth) === "number"?location.Formik.minDepth:labFancyData.length !== 0 && labFancyData[0].labdepthmin !== null ? labFancyData[0].labdepthmin : 0);
    const [maxDepth, setMaxDepth] = useState(location && location.Formik && typeof (location.Formik.maxDepth) === "number"?location.Formik.maxDepth:labFancyData.length !== 0 && labFancyData[0].labdepthmax !== null ? labFancyData[0].labdepthmax : 100);
    const [depthRangeValue, setDepthRangeValue] = useState([location && location.Formik && typeof (location.Formik.minDepth) === "number"?location.Formik.minDepth:labFancyData.length !== 0 && labFancyData[0].labdepthmin !== null ? labFancyData[0].labdepthmin : 0, location && location.Formik && typeof (location.Formik.maxDepth) === "number"?location.Formik.maxDepth:labFancyData.length !== 0 && labFancyData[0].labdepthmax !== null ? labFancyData[0].labdepthmax : 100]);
    const minDepthRange = labFancyData.length !== 0 && labFancyData[0].labdepthmin !== null ? labFancyData[0].labdepthmin : 0;
    const maxDepthRange = labFancyData.length !== 0 && labFancyData[0].labdepthmax !== null ? labFancyData[0].labdepthmax : 100;

    useEffect(() => {
        formik.setFieldValue("minDepth", minDepth);
        formik.setFieldValue("maxDepth", maxDepth);
    }, []);

    const handleDepthRangeChange = (event, newValue) => {
        setMinDepth(newValue[0]);
        setMaxDepth(newValue[1]);
        setDepthRangeValue(newValue);
        // formik.setFieldValue("minDepth", newValue[0]);
        // formik.setFieldValue("maxDepth", newValue[1]);
    };

    const handleDepthRangeChangeCommitted = (event, newValue) => {
        formik.setFieldValue("minDepth", newValue[0]);
        formik.setFieldValue("maxDepth", newValue[1]);
    };

    return (
        <>
            <div className="row mt-7">
                <div className="col-12 font-size-lg font-weight-bold mb-3">{language.CCMODE_THEME1__LIST_DEPTH} %</div>
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-md-12">
                            <Slider
                                getAriaLabel={() => "Depth Range"}
                                value={depthRangeValue}
                                onChangeCommitted={handleDepthRangeChangeCommitted}
                                onChange={handleDepthRangeChange}
                                step={0.01}
                                valueLabelDisplay="auto"
                                // getAriaValueText={depthRangeValue}
                                min={minDepthRange}
                                max={maxDepthRange}
                                color="secondary"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                name="minDepth"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                value={depthRangeValue[0]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    step: "any",
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    if(validateInput(e.target.value)) {
                                        const value = Number(e.target.value);
                                        setMinDepth(value);
                                        setDepthRangeValue([value, depthRangeValue[1]]);
                                        formik.setFieldValue("minDepth", value);
                                    }                                    
                                }}
                            />
                                
                            <TextField
                                className={classes.TextField}
                                margin="dense"
                                variant="outlined"
                                name="maxDepth"
                                InputLabelProps={{ shrink: true }}
                                value={ depthRangeValue[1]}
                                color="secondary"
                                type="number"
                                inputProps={{
                                    step: "any",
                                    style: {
                                      padding: 5,
                                      textAlign:"center"
                                    }
                                 }}
                                onChange={(e) => {
                                    if(validateInput(e.target.value)) {
                                        const value = Number(e.target.value);
                                        setMaxDepth(value);
                                        setDepthRangeValue([depthRangeValue[0], value]);
                                        formik.setFieldValue("maxDepth", value);
                                    }                                    
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
