import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import SVG from "react-inlinesvg";
import { ImagePopup } from "../../../popup/imagePopup";
import { VideoPopup } from "../../../popup/videoPopup";

import { FaAward } from "react-icons/fa";

function total(x) {
    let total = parseFloat(x.O_Rate * x.CARAT).toFixed(2);
    return total.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function Currency(user){
    if (localStorage.getItem("user_currency") === "USD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "INR"){
        const  currencyIcon = "₹"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "HKD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "AUD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "EUR"){
        const  currencyIcon = "€"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "CAD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "CNY"){
        const  currencyIcon = "¥"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "GBP"){
        const  currencyIcon = "£"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "NZD"){
        const  currencyIcon = "$"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "JPY"){
        const  currencyIcon = "¥"
        return currencyIcon
    } else if (localStorage.getItem("user_currency") === "CHF"){
        const  currencyIcon = "₣"
        return currencyIcon
    }else if (localStorage.getItem("user_currency") === "SGD") {
        const  currencyIcon = "$"
        return currencyIcon
    }else if (localStorage.getItem("user_currency") === "THB") {
        const  currencyIcon = "฿"
        return currencyIcon
    }else if (localStorage.getItem("user_currency") === "MYR") {
        const  currencyIcon = "MYR"
        return currencyIcon
    }else if (localStorage.getItem("user_currency") === "BND") {
        const  currencyIcon = "$"
        return currencyIcon
    }else if (localStorage.getItem("user_currency") === "DKK") {
        const  currencyIcon = "DKK"
        return currencyIcon
    }else if (localStorage.getItem("user_currency") === "SEK") {
        const  currencyIcon = "SEK"
        return currencyIcon
    }
}
function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const searchResultColumn = (user,language,hideCertificateNumber,hidePrice,hideEstimatedShippingDays) => {
    let columns = [
    
        {
            Header: language.CCMODE_THEME1_LIST_MEDIA,
            accessor: (d) => (
                <div className="d-flex">
                    <span>
                        {!d.IMAGE ? (
                            <span style={{ width: "14px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : (
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip">Image</Tooltip>
                                }
                            >
                                {/* <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={d.IMAGE}
                                > */}
                                    {/* <BsCardImage /> */}
                                <ImagePopup Image={d.IMAGE} Shape={d.C_Shape} />
                                {/* </a> */}
                            </OverlayTrigger>
    
                        )}
                    </span>
                    <span>
                        {!d.VIDEO ? (
                            <span style={{ width: "14px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : (
                            <>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="tooltip">Video</Tooltip>
                                    }
                                >
                                    <VideoPopup ProductId={d.STOCK_ID} WLDiamondType={d.diamond_type} CERTIFICATE_NO={d.CERTIFICATE_NO} Video={d.VIDEO}/>
                                </OverlayTrigger>
                            </>
                        )}
                    </span>
                    <span>
                        {!d.CERTIFICATE_NO ? (
                            <span style={{ width: "14px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : 
                        hideCertificateNumber ? (
                            <span style={{ width: "14px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : (
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Certificate
                                    </Tooltip>
                                }
                            >
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 text-left"
                                    href={
                                        d.LAB === "IGI" ? `https://www.igi.org/viewpdf.php?r=${d.CERTIFICATE_NO}`  :  d.LAB === "GIA"  ? `https://www.gia.edu/report-check?reportno=${d.CERTIFICATE_NO}` : d.LAB === "HRD" ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${d.CERTIFICATE_NO}` : d.LAB === "GCAL" ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${d.CERTIFICATE_NO}` : d.Certi_link
                                    }
                                >
                                    <FaAward />
                                </a>
                            </OverlayTrigger>
                        )}
                    </span>
                </div>
            ),
            className: "text-left align-middle text-nowrap",
            disableSortBy: true,
        },
        {
            Header: language.CCMODE_THEME1_LIST_SHAPE,
            accessor: "SHAPE",
            className: "text-center align-middle  text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_COLOR,
            // accessor: "C_Color",
            sortMethod: (a, b) => a.localeCompare(b),
            accessor: (d) => (
                <>
                    <span>
                        {d.FANCY_COLOR ? (
                            <>
                                {d.FANCY_INTENSITY} {d.FANCY_OVERTONE} {d.FANCY_COLOR}
                            </>
                        ) : (
                            d.COLOR
                        )}
                    </span>
                </>
            ),
            minwidth: 50,
            className: "text-center align-middle  text-nowrap",
            Cell: (row) => {
                return (
                    <>
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip id="tooltip">{row.value}</Tooltip>
                            }
                        >
                            <span
                                className="d-inline-block text-truncate text-dark"
                                style={{ maxWidth: "80px" }}
                            >
                                {row.value}
                            </span>
                        </OverlayTrigger>
                    </>
                );
            },
        },
        {
            Header: language.CCMODE_THEME1_LIST_CARAT,
            className: "text-center align-middle  text-nowrap",
            accessor: (d) => Number(d.CARAT).toFixed(2),
            sortMethod: (a, b) => Number(a) - Number(b),
            Cell: (row) => {
                return <span>{row.value}</span>;
            },
        },
    
        {
            Header: language.CCMODE_THEME1_LIST_CLARITY,
            accessor: "CLARITY",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_CUT,
            accessor: "CUT",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_POLISH,
            accessor: "POLISH",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_SYMMETRY,
            accessor: "SYMMETRY",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_FLUORESCENCE,
            accessor: "FLUORESCENCE",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: language.CCMODE_THEME1_LIST_LAB,
            accessor: "LAB",
            className: "text-center align-middle text-nowrap",
        },
        {
            Header:  `${localStorage.getItem("user_currency")} /CT`,
            className: "text-right pr-5 align-middle text-nowrap total",
            accessor: d => parseFloat(d.PRICE_PER_CTS),
            sortMethod: (a, b) => Number(a)-Number(b),
            Cell: (row) => {
                return  <span className="d-flex">{Currency(user) } {numberWithCommas(row.value)}</span>;
              },
        },
        {
            Header: language.CCMODE_THEME1_LIST_TOTALPRICE,
            className: "text-right pr-5  text-nowrap align-middle total",
            accessor: d => parseFloat(d.TOTAL_PRICE),
            sortMethod: (a, b) => Number(a)-Number(b),
            Cell: (row) => {
                return  <span className="d-flex">{Currency(user) }{numberWithCommas(row.value)}</span>;
              },
        },
        {
            Header: language.CCMODE_THEME1_LIST_MEASUREMENT,
            // accessor: "C_Length",
            accessor: (d) => (<span className="d-flex">{Number(d.LENGTH).toFixed(2)} x {Number(d.WIDTH).toFixed(2)} x {Number(d.DEPTH).toFixed(2)} </span>),
            className: "text-center align-middle text-nowrap",
        },
        {
            Header: `${language.CCMODE_THEME1__LIST_TABLE} %`,
            accessor: "TABLE_PER",
            className: "text-center align-middle text-nowrap",
            Cell: (row) => {
                return <>{row.value ? <>{parseFloat(row.value).toFixed(2)}%</> : "-"}</>;
            },
        },
        {
            Header: `${language.CCMODE_THEME1__LIST_DEPTH} %`,
            accessor: "DEPTH_PER",
            className: "text-center align-middle text-nowrap",
            Cell: (row) => {
                return <>{row.value ? <>{parseFloat(row.value).toFixed(2)}%</> : "-"}</>;
            },
        },
        {
            Header: language.CCMODE_THEME1__LIST_RATIO,
            accessor: "RATIO",
            className: "text-center align-middle text-nowrap",
            Cell: (row) => {
                return <>{row.value ? <>{parseFloat(row.value).toFixed(2)}</> : "-"}</>;
            },
        },
        {
            Header: language.CCMODE_THEME1__LIST_DELIVEREDIN,
            accessor: "Shipping_Days",
            className: "text-center align-middle text-nowrap",
            Cell: (row) => {
                return <>{row.value} {language.CCMODE_THEME1__LIST_DAYS} </>;
            },
        },
    ]
    if(hidePrice){
        columns.splice(10, 2);
    }
    if(hideEstimatedShippingDays){
        columns.pop()
    }
    return columns
};
