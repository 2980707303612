import React, { useMemo ,useState,useEffect} from "react";
import {Modal,Button} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { GetDiamondDB,StoreDiamondDB } from "../../../_helpers/IndexedDB";
import { GridCardContentSimilar } from "../grid/card/gridCardContentSimilar";
import { FaCalendarAlt } from "react-icons/fa";
import {toAbsoluteUrl} from "../../../_helpers";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import axios from "axios";
import {IUD_SHORTLIST} from "../../../../env_config"
import { BsArrowLeftShort } from "react-icons/bs";
import VideoIframe from "../grid/card/VideoIframe";
import { NODE_API_URL } from "../../../../env_config";
// import { DETAILPAGE_LOG } from "../../../../env_config";
import DetailSkeleton from "../../DetailSkeleton";
import { updateShortlistStoneCount } from "../../../../redux/commonRedux/common.actions";
import { useDispatch, useSelector } from "react-redux";
const CancelToken = axios.CancelToken;
let cancel;

export const ProductDetailMainTheme1 = (props) => {
    const [diamondsearch,setDiamondSearch] = useState([])
    const history = useHistory()
    const location = useLocation();
    
    const [data,setData] = useState(null)
    const [similarstones,setSimilarStones] = useState([])
    const [loader,setLoader] = useState(false)
    const dispatch = useDispatch();

    const shortListedStoneCount = useSelector((state) => {
      return state.ShortlistStoneReducer?.shortlistStoneCount;
    });
    const {
      hideCertificateNumber,
      hideEstimatedShippingDays,
      hidePrice,
    } = useSelector((state) => {
      return state.CcModeSettingReducer;
    });    

    useEffect(() => {
        setLoader(true)
        const userData = localStorage.getItem("userData")
        const user = JSON.parse(userData)
        const FinalObject = {
            StockID:location.state.StockID,
            diamond_type:location.state.diamond_type,
            user_id:user.CustomerId,
            ConsumerId: id,
            currency:localStorage.getItem("user_currency")
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/ccmode/fetchCCModeDiamondDetail`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
             },
            data: FinalObject,
        }).then(res => {
            // console.log(res)
            setLoader(false)
            if(res && res.data && res.data.success){
                // Cancel the previous request if it exists
                if (cancel) {
                    cancel();
                }
        
                // Create a new cancel token for the current request
                const newCancelToken = new CancelToken(function executor(c) {
                    cancel = c;
                });
                let activity = {
                    ...res.data.data,
                    calculateprice:res.data.data.calculateprice.total_our_price,
                    MARKUP_PRICE_PER_CTS:res.data.data.PRICE_PER_CTS
                }
                delete activity.PRICE_PER_CTS
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/storeUserACtivity`,
                    headers: { 
                        "Content-Type": "application/json",
                    //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                    },
                    data: {
                        user_id:FinalObject.user_id,
                        activity_type:"Ccmode Detail",
                        user_agent:navigator.userAgent,
                        activity_data:activity,
                        ip_address:localStorage.getItem("ipData"),
                        consumer_id:id,
                        diamond_type:FinalObject.diamond_type
                    },
                    cancelToken:newCancelToken
                }).then(response => {
                }).catch(error => {
                })
                setData(res.data.data)
                setUpdate(res.data.data.Shortlisted)
                // const finalData = `{ "JsonData":  [{"ProductId":${res.data.data.STOCK_ID}}] , "CustomerId": ${user.CustomerId}, "ConsumerId":${id=== "" ? "-1" : id}, "WLDiamondType": "${res.data.data.diamond_type}" }`;
        // axios({
        //     method: "POST",
        //     url: DETAILPAGE_LOG,
        //     headers: {
        //         "Access-Control-Allow-Origin": "*",
        //         "Content-Type": "application/json",
        //     },
        //     data: finalData,
        // })
        // .then(function(res) {
        //     // setSuccessOpen(true);
        //     // setShowWithoutModel(false);
        // })
        // .catch(function(res) {
        //     // console.log("error",values);
        //     console.log(res);
        // });
        let newobj = {
            StockID:location.state.StockID,
            user_id:user.CustomerId,
            ConsumerId: id,
            shape:res.data.data.SHAPE,
            color:res.data.data.COLOR || "fancy",
            clarity:res.data.data.CLARITY,
            carat:res.data.data.CARAT,
            diamond_type:res.data.data.diamond_type,
            currency:localStorage.getItem("user_currency")
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/ccmode/fetchCCModeSimilarDiamonds`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
             },
            data: newobj,
        }).then(simres => {
            // console.log(simres,"simres")
            if(simres && simres.data && simres.data.data && simres.data.data.length){
                setSimilarStones(simres.data.data)
            }
        })
            }
        })
    },[location.state])
    // console.log(similarstones,"similarstones")
    const [showWithoutModel, setShowWithoutModel] = React.useState(false);
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const id = useMemo(()=>localStorage.getItem("C_id"),[])
    const userCurrency = useMemo(()=>localStorage.getItem("userCurrency"),[])
    const usercur = JSON.parse(userCurrency)
    let ans ;
    // if (diamondsearch !== undefined) {
    //         const cxProduct = data;
    //          ans = diamondsearch.filter((ele) => {
    //             if (ele.C_Weight>=cxProduct.C_Weight-0.1 && ele.C_Weight<=cxProduct.C_Weight+0.1 ) 
    //                 {
    //                     if (ele.ProductId !== cxProduct.ProductId &&
    //                     ele.C_Shape === cxProduct.C_Shape &&
    //                     ele.C_Color === cxProduct.C_Color &&
    //                     ele.C_Cut === cxProduct.C_Cut &&
    //                     (ele.ClarityCalc>=cxProduct.ClarityCalc-1 && ele.ClarityCalc<=cxProduct.ClarityCalc+1))
    //                     {
    //                         ele.absPrice = Math.abs(cxProduct.OurPrice - ele.OurPrice)
    //                         return ele;
    //                     }
    //                 }
    //             })
    //         function sortByProperty(property) {
    //             return function (a, b) {
    //                 if (a[property] > b[property])
    //                     return 1;
    //                 else if (a[property] < b[property])
    //                     return -1;

    //                 return 0;
    //             }
    //         }
    //         ans.sort(sortByProperty("absPrice"))
    //     }
    const [update,setUpdate] = React.useState(0);
      // -------------------------------------------------

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------

    
    function handleClose(event, reason) {
        setSuccessOpen(false)
        setOpen(false);
        setShowVideo(false);
        setShowWithoutModel(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

  
    const openVideoModal = () => setShowVideo(true);
    
    function openHeartModal() {
        setShowHeartImg(true)
    }
    function openAssetModal() {
        setShowAssetImg(true)
    }
    function openArrowModal() {
        setShowArrowImg(true)
    }
    function withoutSession() {
        setOpen(true);
    }
    function openModal(data) {
        if(!update){
            setShowWithoutModel(true);
            handleShortlist(data)
        }else{
            history.push({  pathname: '/shortlist'}) 
        }
    }
     // Get device information
     function detectDevice() {
        // Check if the user agent contains the substring "Mobi"
        return /Mobi/.test(navigator.userAgent) ? "Mobile" : "Pc";
    }

    function detectOS() {
        const userAgent = navigator.userAgent;
        if (/Windows/.test(userAgent)) {
            return "Windows";
        } else if (/Macintosh/.test(userAgent)) {
            return "Mac OS";
        } else if (/Linux/.test(userAgent)) {
            return "Linux";
        } else if (/Android/.test(userAgent)) {
            return "Android";
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
            return "iOS";
        } else {
            return "Unknown";
        }
    }

    function handleShortlist(props) {

        const deviceType = detectDevice();
        const os = detectOS();

        const deviceInfo = `${deviceType} - ${os}`; // Combine device type and OS into a single field
        // console.log(props,"propsprops")
        // const newdiamondata = diamondsearch.map(value => {
        //     if(value.ProductId === props.ProductId){
        //         value.Shortlisted = 1
        //     }
        //     return value
        // })
        // console.log(newdiamondata,"newdiamondata")
        // StoreDiamondDB(newdiamondata)
        const values = JSON.stringify({
            Tag: "I",
            Id: 0,
            CustomerId: user.CustomerId,
            ConsumerId: id,
            ProductId: props.STOCK_ID,
            CertiNo: props.CERTIFICATE_NO,
            WLDiamondType: props.diamond_type,
            Currency: props.CURRENCY,
            CurrencyConversionRate: props.CURRENCY_RATE,
            MarkUpNatural: 0,
            MarkUpNaturalFancy: 0,
            MarkUpLab: 0,
            MarkUpLabFancy: 0,
            OurRate: props.calculateprice?props.calculateprice.T_C_Rate:0,
            OurPrice: props.calculateprice?props.calculateprice.total_our_price:0,
            MarkUpRate: Math.round(props.markupdollpercar * 100)/100,
            MarkUpPrice: Math.round(props.markupprice * 100)/100,
            // PRICE_PER_CTS: props.PRICE_PER_CTS,
            // TOTAL_PRICE: props.TOTAL_PRICE,
            CreatedAt: "0000-00-00 00:00:00",
            Tax: props.taxvalue,
            ip:localStorage.getItem("ipData"),
            device:deviceInfo,
        });
        // console.log(values);
        axios({
            method: "POST",
            url: `${NODE_API_URL}/ccmode/shortlistStone`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            data: values,
        })
            .then(function(res) {
                // console.log(res.data);
                // alert("Data added successfully");
                setUpdate(1)
                // setSuccessOpen(true);
                // setShowWithoutModel(false);

                dispatch(
                  updateShortlistStoneCount({
                    shortListedStoneCount: shortListedStoneCount + 1,
                  })
                );

                let diamond_details = {
                    // "ProductId":props.STOCK_ID,
                    // "ListName":"Test",
                    // "ConsumerName":"pancham",
                    // "EMail":"Kumarpancham8873@gmail.com",
                    // "ContactNo":"7004896788",
                    // "Notes":"Test Mode",
                    "STOCK_ID": props.STOCK_ID,
                    "Shipping_Days": props.Shipping_Days,
                    "SHAPE": props.SHAPE,
                    "CARAT": props.CARAT,
                    "CLARITY": props.CLARITY,
                    "CUT": props.CUT,
                    "POLISH": props.POLISH,
                    "SYMMETRY": props.SYMMETRY,
                    "FLUORESCENCE": props.FLUORESCENCE,
                    "LAB": props.LAB,
                    "CERTIFICATE_LINK": props.CERTIFICATE_LINK,
                    "WIDTH": props.WIDTH,
                    "LENGTH": props.LENGTH,
                    "DEPTH": props.DEPTH,
                    "DEPTH_PER": props.DEPTH_PER,
                    "TABLE_PER": props.TABLE_PER,
                    "CROWNANGLE": props.CROWNANGLE,
                    "CROWNHEIGHT": props.CROWNHEIGHT,
                    "PAVILIONHEIGHT": props.PAVILIONHEIGHT,
                    "PAVILIONANGLE": props.PAVILIONANGLE,
                    "CERTIFICATE_NO": props.CERTIFICATE_NO,
                    "MARKUP_PRICE_PER_CTS": props.PRICE_PER_CTS,
                    "TOTAL_PRICE": props.TOTAL_PRICE,
                    "ORIGIN": props.ORIGIN,
                    "TREATMENT": props.TREATMENT,
                    "BRAND": props.BRAND,
                    "SHADE": props.SHADE,
                    "MILKY": props.MILKY,
                    "EYE_CLEAN": props.EYE_CLEAN,
                    "COUNTRY": props.COUNTRY,
                    "CURRENCY": props.CURRENCY,
                    "CURRENCY_RATE": props.CURRENCY_RATE,
                    "CULET": props.CULET,
                    "GIRDLE": props.GIRDLE,
                    "GIRDLE_CONDITION": props.GIRDLE_CONDITION,
                    "KEY_TO_SYMBOL": props.KEY_TO_SYMBOL,
                    "RATIO": props.RATIO,
                    "IMAGE": props.IMAGE,
                    "VIDEO": props.VIDEO,
                    "FANCY_INTENSITY": props.FANCY_INTENSITY,
                    "FANCY_OVERTONE": props.FANCY_OVERTONE,
                    "FANCY_COLOR": props.FANCY_COLOR,
                    "COLOR": props.COLOR,
                    "diamond_type": props.diamond_type,
                    "girdle_thin": props.girdle_thin,
                    "Pav_Ag": props.Pav_Ag,
                    "Crn_Ag": props.Crn_Ag,
                    "calculateprice": props.calculateprice.total_our_price,
                    "Shortlisted": props.Shortlisted,
                    "taxvalue": props.taxvalue
                }
                // axios({
                //     method: "POST",
                //     url: `${NODE_API_URL}/ccmode/storeUserACtivity`,
                //     headers: { 
                //         "Content-Type": "application/json"
                //     //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                //      },
                //     data: {
                //         user_id:user.CustomerId,
                //         activity_type:"Ccmode Shortlist",
                //         user_agent:navigator.userAgent,
                //         activity_data:{
                //             ...diamond_details
                //         },
                //         ip_address:localStorage.getItem("ipData"),
                //         consumer_id:id,
                //         diamond_type:props.diamond_type
                //     }
                // }).then(response => {
                // }).catch(error => {
                // })
            })
            .catch(function(res) {
                console.log("error",values);
                // console.log(res);
            });
    }

    function Currency(){
        if (localStorage.getItem("user_currency") === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SGD") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "THB") {
            const  currencyIcon = "฿"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "MYR") {
            const  currencyIcon = "MYR"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "BND") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "DKK") {
            const  currencyIcon = "DKK"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SEK") {
            const  currencyIcon = "SEK"
            return currencyIcon
        }
    }
    // const backBtn = () =>{
    //     history.push({  pathname: '/grid'})
    // }

    function certificateLink(){
        if (data.LAB === "IGI"){
            const  certificate_link = `https://www.igi.org/viewpdf.php?r=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.CERTIFICATE_LINK){
            const certificate_link = data.CERTIFICATE_LINK
            return certificate_link
        }
    }

    function availabilityIcon(){
        if (data.Availability === 'Guaranteed'){
            const availabilty_icon= <span className="font-weight-bolder label label-success">G</span>
           	return  availabilty_icon
        }else if(data.Availability === 'Memo'){
            const availabilty_icon= <span className="font-weight-bolder label label-primary">M</span>
           	return  availabilty_icon
        }else if(data.Availability === 'Hold'){
            const availabilty_icon= <span className="font-weight-bolder label label-primary">H</span>
           	return  availabilty_icon
        }else if(data.Availability === 'Not Available'){
            const availabilty_icon= <span className="font-weight-bolder label label-danger">NA</span>
           	return  availabilty_icon
        }else if(data.Availability === 'Subject to Prior sale'){
            const availabilty_icon= <span className="font-weight-bolder label label-warning">S</span>
            return  availabilty_icon
        }else{
            const availabilty_icon= <span className="font-weight-bolder label label-white">-</span>
            return  availabilty_icon
        }
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <>
            {loader?<DetailSkeleton/>:data?<div className={`container product_detail theme1 ${
                    data.diamond_type === "N" ? "natTab" : "labTab"
                }`}>
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-body">
                        <div className="row">
                            {/* data: {JSON.stringify(data)} */}
                            <div className="col-12">
                                <span className="cursor-pointer text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> {props.language.CCMODE_DIAMOND_DETAILS_GOTORESULT}</span>
                            </div>
                            <div className="col-md-6 d-flex">
                                <ul className="img_thumb_box mt-4 ml-5 pl-0">
                                    <li className="img_thumb">
                                        {data.IMAGE ? (
                                            <img
                                                className="img thumb"
                                                alt={data.SHAPE}
                                                src={data.IMAGE}
                                                onError={e => {
                                                    e.currentTarget.src = toAbsoluteUrl(
                                                        `/media/shape/${data.SHAPE.toLowerCase()}-no.png`
                                                    )
                                                }} 
                                            />
                                        ) : (
                                            <img
                                                className="img thumb"
                                                alt="shape"
                                                
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${data.SHAPE.toLowerCase()}-no.png`
                                                )}
                                            />
                                        )}
                                    </li>
                                    {data.VIDEO ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                                            {/* <FaVideo className="font-size-sm text-primary cursor-pointer"  /> */}
                                            {/* <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/heart-no.png`)}/> */}
                                        </li>
                                    ):('')}
                                    {data.AWS_Heart ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                                           
                                        </li>
                                        
                                    ):('')}
                                    {data.AWS_Arrow ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                                            
                                        </li>
                                    ):('')}
                                    {data.AWS_Asset ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                                            
                                        </li>
                                    ):('')}
                                </ul>
                                <div className="img_box mt-4">
                                    {data.IMAGE ? (
                                        <img
                                            className="img-fluid"
                                            alt={data.SHAPE}
                                            src={data.IMAGE}
                                            onError={e => {
                                                e.currentTarget.src = toAbsoluteUrl(
                                                    `/media/shape/${data.SHAPE.toLowerCase()}-no.png`
                                                )
                                            }}
                                        />
                                    ) : (
                                        <img
                                            className="img-fluid"
                                            alt="shape"
                                            onError={e => {
                                                e.currentTarget.src = toAbsoluteUrl(
                                                    `/media/shape/noimage.jpg`
                                                )
                                            }}
                                            src={toAbsoluteUrl(
                                                `/media/shape/${data.SHAPE.toLowerCase()}-no.png`
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12 my-3 d-flex justify-content-lg-start align-items-center">
                                        <span className="font-size-sm text-primary mr-4">
                                            {
                                                hideCertificateNumber ?
                                                <>{data.LAB}</> :
                                                <a
                                                    id={data.CERTIFICATE_NO}
                                                    href={certificateLink()}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="font-size-sm text-primary"
                                                >
                                                    {data.LAB}-{data.CERTIFICATE_NO}
                                                </a>
                                            }                                            
                                        </span>
                                        <div className="font-size-sm mr-1 text-dark-50">Stock No: <div className="font-size-sm text-dark-50 font-weight-bold d-inline-block">{data.diamond_type === "N" ? "N" :"L"}-{data.STOCK_ID ? data.STOCK_ID : "-"}</div></div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <span className="text-dark font-weight-bold text-capitalize font-size-h2">
                                        <span className="pr-2">{data.LAB}</span>
                                            <span className="pr-2">
                                                {data.CARAT && data.CARAT.toFixed(2)}
                                            </span>
                                            <span className="pr-2">{data.SHAPE}</span>
                                            { (data.FANCY_COLOR) ?
                                                (
                                                    <>
                                                        <span className="pr-2">{data.FANCY_INTENSITY}</span>
                                                        <span className="pr-2">{data.FANCY_OVERTONE}</span>
                                                        <span className="pr-2">{data.FANCY_COLOR}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="pr-2">{data.COLOR}</span>
                                                    </>
                                                )
                                            }
                                            <span className="pr-2">{data.CLARITY}</span>
                                            <span className="pr-2">{data.CUT}</span>
                                            <span className="pr-2">{data.POLISH}</span>
                                            <span className="pr-2">{data.SYMMETRY}</span>
                                            <span className="pr-2">{data.FLUORESCENCE}</span>
                                        </span>
                                    </div>
                                    {                                        
                                        !hidePrice && (
                                            <div className="col-12 d-flex align-items-end mt-5">
                                                <span>
                                                    <div className="font-size-sm text-dark-50">{Currency()}/CT {numberWithCommas(data.PRICE_PER_CTS)}</div>
                                                    <div className="font-size-h1 font-weight-bold text-primary">{Currency()}{numberWithCommas(data.TOTAL_PRICE)}</div>
                                                </span>
                                            </div>
                                        )
                                    }                                    
                                    <div className="col-12 mt-5">
                                        {id === "-1" || id=== "-2" || id === "" ? (
                                            <>
                                                <button
                                                    onClick={() => withoutSession()}
                                                    className={`btn font-weight-bold ${update ? "btn-info" : "btn-danger"}`}
                                                >
                                                    {update ? props.language.CCMODE_THEME1_GRID_SHORTLISTEDBTN : props.language.CCMODE_THEME1_GRID_SHORTLISTBTN}
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                 <button
                                                    key={data.STOCK_ID}
                                                    onClick={() => openModal(data)}
                                                    className={`btn font-weight-bold ${update ? "btn-info" : "btn-primary"}`}
                                                >
                                                    {update ? props.language.CCMODE_THEME1_GRID_SHORTLISTEDBTN : props.language.CCMODE_THEME1_GRID_SHORTLISTBTN}
                                                </button>
                                                <Modal
                                                    show={showWithoutModel}
                                                    onHide={handleClose}
                                                    backdrop="static"
                                                    keyboard={false}
                                                >
                                                    <Modal.Body>
                                                        <div className="text-center mb-10"> <h3 className="text-dark font-weight-bold">You have just added</h3></div>
                                                        <div className="d-flex w-100 mb-4 pb-4 px-4 justify-content-between">
                                                            <div className="d-flex">
                                                                <div className="text-center p-0">
                                                                    {data.IMAGE &&  data.IMAGE !=="null" ? (
                                                                        <img
                                                                            className="img-fluid mr-2"
                                                                            alt={data.SHAPE}
                                                                            src={data.IMAGE}
                                                                            onError={e => {
                                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                                    `/media/shape/${data.SHAPE && data.SHAPE.toLowerCase()}-no.png`
                                                                                )
                                                                            }}
                                                                            width="145px"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            className="img-fluid mr-2"
                                                                            alt={data.SHAPE}
                                                                            src={toAbsoluteUrl(
                                                                                `/media/shape/${data.SHAPE && data.SHAPE.toLowerCase()}-no.png`
                                                                            )}
                                                                            onError={e => {
                                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                                    `/media/shape/noimage.jpg`
                                                                                )
                                                                            }}
                                                                            width="145px"
                                                                            style={{ backgroundColor: "#f4f4f4" }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="pl-2 p-0 text-left">
                                                                    <div className={`mb-1 ${data.diamond_type === "N" ? "natTab" : "labTab"}`}>
                                                                        <span className={`${ data.diamond_type === "N" ? data.FANCY_COLOR === "fancy" ? "F" : "W" : data.FANCY_COLOR === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                                                            {data.diamond_type === "N" || data.diamond_type === "W" ? data.FANCY_COLOR === "fancy"  ? "NF" : "N" : data.FANCY_COLOR === "fancy" ? "LF" : "L"}
                                                                        </span>
                                                                        <span className={`text-primary font-weight-bold small ml-1`}>
                                                                            {data.diamond_type === "N" ? "N" : "L"}-{data.STOCK_ID}
                                                                        </span>
                                                                        {
                                                                            hideCertificateNumber ?
                                                                            <span className="text-primary font-weight-bold mx-2 small">{data.Lab}</span> :
                                                                            <a
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                className="text-primary font-weight-bold mx-2 small"
                                                                                href={
                                                                                    data.LAB === "IGI"
                                                                                        ? `https://www.igi.org/viewpdf.php?r=${data.CERTIFICATE_NO}`
                                                                                        : data.LAB === "GIA"
                                                                                        ? `https://www.gia.edu/report-check?reportno=${data.CERTIFICATE_NO}`
                                                                                        : data.LAB === "HRD"
                                                                                        ? `http://ws2.hrdantwerp.com/HRprops.CertificateService.WebAPI/certificate?certificateNumber=${data.CERTIFICATE_NO}`
                                                                                        : data.LAB === "GCAL"
                                                                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.CERTIFICATE_NO}`
                                                                                        : data.Certi_link
                                                                                }
                                                                            >

                                                                                {data.LAB}-{data.CERTIFICATE_NO}
                                                                            </a>
                                                                        }                                                            
                                                                    </div>
                                                                    <div className="text-dark font-size-h6 mb-2 font-weight-bold d-block">
                                                                        {data.SHAPE}{" "}
                                                                        {parseFloat(data.CARAT).toFixed(2)}{" "}
                                                                        {data.COLOR === "fancy" ? (
                                                                            <>
                                                                                {data.FANCY_COLOR}{" "}
                                                                            </>
                                                                        ) : (
                                                                            <>{data.COLOR}{" "}</>
                                                                        )}
                                                                        {data.CLARITY}{" "}{data.CUT}{" "}{data.POLISH}{" "}{data.SYMMETRY}{" "}
                                                                        {data.FLUORESCENCE}
                                                                    </div>
                                                                    <div >
                                                                        {
                                                                            !hidePrice && (                                                            
                                                                                <div className="d-flex">
                                                                                    <div className="mr-4">
                                                                                        <p className="text-muted font-weight-bold small mb-0 text-uppercase">{localStorage.getItem("user_currency")}/CT</p>
                                                                                        <p className="text-dark font-size-h6 font-weight-bold">
                                                                                            <span>{Currency()}</span>
                                                                                            {numberWithCommas(data.PRICE_PER_CTS)}
                                                                                        </p>
                                                                                    </div>
                                                                                    
                                                                                    <div className="">
                                                                                        <p className="text-muted font-weight-bold small mb-0 text-uppercase">Total</p>
                                                                                        <p className="text-dark font-size-h6 font-weight-bold">
                                                                                            <span>{Currency(localStorage.getItem("user_currency"))}</span>
                                                                                            {numberWithCommas(data.TOTAL_PRICE)}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }                                                                                                        
                                                                    </div>
                                                                    <div>
                                                                        <Button variant="secondary" onClick={handleClose}> Close </Button>
                                                                        <Link to={`/shortlist`} className="btn ml-2 btn-primary" > Go to cart </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-12 mt-5">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">M</div>
                                            <div className="text-primary ml-2">  {parseFloat(data.LENGTH).toFixed(2)} x {parseFloat(data.WIDTH).toFixed(2)} x {parseFloat(data.DEPTH).toFixed(2)}  </div>
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">T</div>
                                            <div className="text-primary ml-2">  {data.TABLE_PER ? <>{parseFloat(data.TABLE_PER).toFixed(2)}%</> : "-"}  </div>
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">D</div>
                                            <div className="text-primary ml-2">  {data.DEPTH_PER ? <>{parseFloat(data.DEPTH_PER).toFixed(2)}%</> : "-"}  </div>
                                        </span>
                                    </div>
                                    {data.RATIO ? (
                                        <div className="col-12">
                                            <span className="d-flex align-items-center font-weight-bold">
                                                <div className="text-dark mr-1">R</div>
                                                <div className="text-primary ml-2">  {data.RATIO ? <>{parseFloat(data.RATIO).toFixed(2)}</> : "-"}  </div>
                                            </span>
                                        </div>
                                    ):('')}
                                    {
                                        !hideEstimatedShippingDays && (
                                            <div className="col-12">
                                                <span className="d-flex align-items-center font-weight-bold">
                                                    <span className="text-dark mr-1"><FaCalendarAlt className="text-danger mr-2" /></span> {props.language.CCMODE_DIAMOND_DETAILS_SHIPPINGDAYS}:
                                                    <span className="text-primary ml-2">{data.Shipping_Days ? data.Shipping_Days : "-"} {props.language.CCMODE_THEME1__LIST_DAYS}</span>
                                                </span>
                                            </div>
                                        )
                                    }                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-header justify-content-center mb-3">
                        <div className="card-title m-0">
                            <span className="card-label text-dark">
                                {props.language.CCMODE_DIAMOND_DETAILS_DESCRIPTION}
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {/* data: {JSON.stringify(data)} */}
                            <div className="col-md-6">
                                <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> {props.language.CCMODE_DIAMOND_DETAILS_DIAMONDDETAIL} </div>
                                {/* <div className="row mb-1">
                                    <div className="col-6"> Availability </div>
                                    <div className="col-6 font-weight-bolder"> {data.Availability ? data.Availability : "-"} </div>
                                </div> */}
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_STOCKID} </div>
                                    <div className="col-6 font-weight-bolder"> {data.diamond_type === "N" ? "N" :"L"}-{data.STOCK_ID ? data.STOCK_ID : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_SHAPE} </div>
                                    <div className="col-6 font-weight-bolder">  {data.SHAPE ? data.SHAPE : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_CARAT} </div>
                                    <div className="col-6 font-weight-bolder">  {data.CARAT ? data.CARAT : "-"} </div>
                                </div>
                                { (data.COLOR !=="fancy") ? (
                                    <div className="row mb-1">
                                        <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_COLOR} </div>
                                        <div className="col-6 font-weight-bolder">  {data.COLOR ? data.COLOR : "-"} </div>
                                    </div>
                                ):('')}
                               
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_CLARITY} </div>
                                    <div className="col-6 font-weight-bolder">  {data.CLARITY ? data.CLARITY : "-"} </div>
                                </div>
                                { (data.CUT) ? (
                                    <div className="row mb-1">
                                        <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_CUT} </div>
                                        <div className="col-6 font-weight-bolder">  {data.CUT ? data.CUT : "-"} </div>
                                    </div>
                                ):('')}
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_POLISH} </div>
                                    <div className="col-6 font-weight-bolder">  {data.POLISH ? data.POLISH : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_SYMMETRY} </div>
                                    <div className="col-6 font-weight-bolder">  {data.SYMMETRY ? data.SYMMETRY : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_FLOUR}</div>
                                    <div className="col-6 font-weight-bolder">  {data.FLUORESCENCE ? data.FLUORESCENCE : "-"} </div>
                                </div>
                                {/* <div className="row mb-1">
                                    <div className="col-6"> Fluorescence Intensity </div>
                                    <div className="col-6 font-weight-bolder">  {data.F_Intensity ? data.F_Intensity : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> Fluorescence Color </div>
                                    <div className="col-6 font-weight-bolder">  {data.F_Color ? data.F_Color : "-"} </div>
                                </div> */}
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_LAB}</div>
                                    <div className="col-6 font-weight-bolder">  {data.LAB ? data.LAB : "-"} </div>
                                </div>
                                {
                                    !hideCertificateNumber && (
                                        <div className="row mb-1">
                                            <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_CERTIFICATE}</div>
                                            <div className="col-6 font-weight-bolder">
                                                <a
                                                    id={data.CERTIFICATE_NO}
                                                    href={certificateLink()}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="font-size-sm text-primary"
                                                >
                                                    {data.CERTIFICATE_NO}
                                                </a>
                                            </div>
                                        </div>
                                    )
                                }                                
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_LOCATION}</div>
                                    <div className="col-6 font-weight-bolder">  {data.COUNTRY ? data.COUNTRY : "-"} </div>
                                </div>
                                {
                                    !hideEstimatedShippingDays && (
                                        <div className="row mb-1">
                                            <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_SHIPPING_DAYS}</div>
                                            <div className="col-6 font-weight-bolder">  {data.Shipping_Days ? <>{data.Shipping_Days} {props.language.CCMODE_THEME1__LIST_DAYS}</> : "-"} </div>
                                        </div>
                                    )
                                }                                
                            </div>
                            <div className="col-md-6"> 
                                { (data.FANCY_COLOR) ? (
                                    <>
                                        <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary">Fancy Color Details </div>
                                        <div className="row mb-1">
                                            <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_INTENSITY}</div>
                                            <div className="col-6 font-weight-bolder">  {data.FANCY_INTENSITY ? data.FANCY_INTENSITY : "-"} </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_OVERTONE}</div>
                                            <div className="col-6 font-weight-bolder">  {data.FANCY_OVERTONE ? data.FANCY_OVERTONE : "-"} </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_FANCYCOLOR}</div>
                                            <div className="col-6 font-weight-bolder">  {data.FANCY_COLOR ? data.FANCY_COLOR : "-"} </div>
                                        </div>
                                    </>
                                ):('')}
                               
                                <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> {props.language.CCMODE_DIAMOND_DETAILS_PARAMDETAILS} </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_MEASUREMENT}</div>
                                    <div className="col-6 font-weight-bolder">  {parseFloat(data.LENGTH).toFixed(2)} x {parseFloat(data.WIDTH).toFixed(2)} x {parseFloat(data.DEPTH).toFixed(2)}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_TABLE} %</div>
                                    <div className="col-6 font-weight-bolder">  {data.TABLE_PER ? `${parseFloat(data.TABLE_PER).toFixed(2)}%` : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_DEPTH} %</div>
                                    <div className="col-6 font-weight-bolder">  {data.DEPTH_PER ? `${parseFloat(data.DEPTH_PER).toFixed(2)}%` : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_CROWN}</div>
                                    <div className="col-6 font-weight-bolder">  {data.CROWNHEIGHT ? `${data.CROWNHEIGHT}%` : "-"}, {data.Crn_Ag ? `${data.Crn_Ag}°` : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_CROWNPAVILION}</div>
                                    <div className="col-6 font-weight-bolder">  {data.PAVILIONHEIGHT ? `${data.PAVILIONHEIGHT}%` : "-"}, {data.Pav_Ag ? `${data.Pav_Ag}°` : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_GIRDLE}</div>
                                    <div className="col-6 font-weight-bolder">   {data.GIRDLE ? data.GIRDLE : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_GIRDLE_THICK}</div>
                                    <div className="col-6 font-weight-bolder">   {data.GIRDLE_CONDITION ? data.GIRDLE_CONDITION : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_GIRDLE_THIN}</div>
                                    <div className="col-6 font-weight-bolder">   {data.girdle_thin ? data.girdle_thin : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_GIRDLE_CUTLET}</div>
                                    <div className="col-6 font-weight-bolder">   {data.CULET ? data.CULET : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_SHADE}</div>
                                    <div className="col-6 font-weight-bolder">{data.SHADE ? data.SHADE : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_MILKY}</div>
                                    <div className="col-6 font-weight-bolder">{data.MILKY ? data.MILKY : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_EYECLEAN}</div>
                                    <div className="col-6 font-weight-bolder">{data.EYE_CLEAN ? data.EYE_CLEAN : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_KEY_TO_SYMBOL}</div>
                                    <div className="col-6 font-weight-bolder">{data.KEY_TO_SYMBOL ? data.KEY_TO_SYMBOL : "-"}  </div>
                                </div>
                                {data.diamond_type === "L" ? 
                                    <>
                                        { (data.Growth_Type) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_GROWTHTYPE} </div>
                                                <div className="col-6 font-weight-bolder">  {data.Growth_Type ? data.Growth_Type : "-"} </div>
                                            </div>
                                        ):('')}
                                        { (data.TREATMENT) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_TREATMENT} </div>
                                                <div className="col-6 font-weight-bolder">  {data.TREATMENT ? data.TREATMENT : "-"} </div>
                                            </div>
                                        ):('')}
                                    </>
                                :   
                                    <>
                                        { (data.ORIGIN) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_ORIGIN} </div>
                                                <div className="col-6 font-weight-bolder">  {data.ORIGIN ? data.ORIGIN : "-"} </div>
                                            </div>
                                        ):('')}
                                        { (data.BRAND) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_BRAND} </div>
                                                <div className="col-6 font-weight-bolder">  {data.BRAND ? data.BRAND : "-"} </div>
                                            </div>
                                        ):('')}
                                         { (data.TREATMENT) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_TREATMENT} </div>
                                                <div className="col-6 font-weight-bolder">  {data.TREATMENT ? data.TREATMENT : "-"} </div>
                                            </div>
                                        ):('')}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-header justify-content-center mb-3">
                        <div className="card-title m-0">
                            <span className="card-label text-dark">
                                {props.language.CCMODE_DIAMOND_DETAILS_SIMILAR_DIAMONDS}
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row m-0">
                            {similarstones.length ?  
                                similarstones.map((val) => {
                                    // console.log(val)
                                    return (
                                        <GridCardContentSimilar
                                            key={val.ProductId}
                                            {...val}
                                            language={props.language}
                                            hidePrice={hidePrice}
                                            hideCertificateNumber={hideCertificateNumber}
                                        />
                                    );
                                })
                             : <div className="col-12 text-center">
                                <p className="font-size-h5"> No records found</p>
                             </div> }
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <MySnackbarContentWrapper
                        onClose={handleClose}
                        variant="error"
                        message="You must start a session before adding stones to your cart"
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={successOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <MySnackbarContentWrapper
                        onClose={handleClose}
                        variant="success"
                        message="Your stone is added to cart"
                    />
                </Snackbar>
                {/* <Modal
                    show={showVideo}
                    onHide={handleClose}
                    size="xl"
                >
                    <Modal.Body className="text-center">
                        <iframe title="Video" src={data.diamond_type === "N" ? `https://pro360video.com/video.php?refno=${data.CERTIFICATE_NO}` : `https://pro360video.com/labgrown.php?refno=${data.CERTIFICATE_NO}` } height='600px' width='700px' frameBorder='0'></iframe>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal> */}
                <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={data.diamond_type === "N" ? `https://pro360video.com/video.php?refno=${data.CERTIFICATE_NO}` : `https://pro360video.com/labgrown.php?refno=${data.CERTIFICATE_NO}` }
                />
                <Modal
                    show={showHeartImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Heart} alt={data.SHAPE} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showArrowImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Arrow} alt={data.SHAPE} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showAssetImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Asset} alt={data.SHAPE} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>:<div className="card custom-card gutter-b">
        <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
            <div className="text-center">
                <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                <div className="mt-8 font-size-h4 text-capitalize text-primary"><strong>No Record Found</strong></div>
                <button className="btn btn-primary mt-5 px-8" onClick={() => history.push("/searchdiamond")}>Back To Search</button>
                {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
            </div>
        </div>
    </div>}
        </>
    );
};
