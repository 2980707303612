import React ,{useEffect,useState,useMemo} from "react"
import axios from "axios"
import { MenuItem, FormControl, Select, makeStyles, InputLabel } from "@material-ui/core"
import { FaInfoCircle } from 'react-icons/fa';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import { FETCH_CURRENCY } from "../../../../env_config";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 110,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    menuItemScroll: {
        maxHeight: '200px',
        overflowY: 'auto',
      },
  }));
export default function MultiCurrency(props){
    const classes = useStyles();
    const [currValueInr,setCurrValueInr] = React.useState(81.36);
    const [currValueUsd,setCurrValueUsd] = React.useState(1);
    const [currency, setCurrency] = React.useState('USD');
    const [currencies,setCurrencies] = React.useState([])
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const [curRate, setCurRate] = React.useState([]);
    const [disabledropdown,setDisableDropdown] = useState(false)
    const handleChange = (event) => {
        setCurrency(event.target.value)
        localStorage.setItem("user_currency",event.target.value)
        window.location.reload(false);
    }
    useEffect(() => {
        getCurrency()
        if(localStorage.getItem("user_currency")){
            setCurrency(localStorage.getItem("user_currency"))
        }
        else{
            if(props.themeData.Currency){
                setCurrency(props.themeData.Currency)
                localStorage.setItem("user_currency",props.themeData.Currency)
            }
        }
    },[props.themeData])
    // useEffect(() => {
    //     // console.log(window.location.pathname,"window.location.href")
    //     if(props.themeData && props.themeData.Theme === "theme1"){
    //         if(window.location.pathname !== '/searchdiamond'){
    //             setDisableDropdown(true)
    //           }
    //           else{
    //             setDisableDropdown(false)
    //           }
    //     }
    //   },[window.location.href,props.themeData])
    // console.log(currencies,"currencies")
    function getCurrency(){
        const currencyData = { "Currency": user.Currency };
        axios({
          method: "POST",
          url: FETCH_CURRENCY,
          headers: {
              "Content-Type": "application/json",
          },
              data: currencyData,
          })
          .then(function(res) {
                if(res && res.data && res.data.allcurrency){
                    let currs = []
                    delete res.data.allcurrency.base_currency
                    delete res.data.allcurrency.bus_date
                    delete res.data.allcurrency.updated_dt
                    delete res.data.allcurrency.timestamp_val
                    for(let keys in res.data.allcurrency){
                      if(!keys.includes("org")){
                        currs.push({
                          currencyname:keys,
                          currencyvalue:res.data.allcurrency[keys]
                        })
                      }
                    }
                    currs.push({
                        currencyname:"cur_usd",
                        currencyvalue:1
                      })
                    setCurrencies(currs)
                }
          })
          .catch(function(res) {
              console.error(res);
          });
      }
    return(
        <div className="MultiLingual">
      <FormControl className={classes.formControl}>
        <Select
          labelId="MultiLingual"
          id="MultiLingual"
          value={currency}
          onChange={handleChange}
          variant="outlined"
          margin="dense"
          MenuProps={{
            classes: {
              paper: classes.menuItemScroll,
            },
          }}
          disabled={disabledropdown}
        >
          {currencies.map(((values, i) => (
            <MenuItem key={i} value={values.currencyname.split('_')[1].toUpperCase()}>{values.currencyname.split('_')[1].toUpperCase()}-{(Math.round(values.currencyvalue * 100) / 100).toFixed(2)}</MenuItem>
          )))}
        </Select>
      </FormControl>
    </div>
    )
}